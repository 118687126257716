/**
 * @module api-scm:procurementOrder
 */

import service from '@/service'

/**
 * @param {{
 * date:Array<string>,
 * deptId:number,
 * enrollmentTime:string,
 * isEnable:(1|2),
 * keywords:string,
 * limit:number,page:number,
 * sort:string,workStatus:(1|2)}} params
 * @description  查询采购列表
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/get/getList',
    method: 'post',
    data
  })
}
/**
 *
 * @param {{code:string}} params
 */
export function getDetailList(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/get/getListDown',
    method: 'get',
    params
  })
}

/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/save/save',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/update',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}
/**
 * // 提交
 * @param {Array<string|number>} ids
 */

export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} ids
 */

export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/purchaseInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * // 下推
 * @param {object} data
 */

export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/pushDown',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 详情
 * @param {number} id
 * @returns {any}
 */
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/info',
    method: 'get',
    params: { id }
  })
}

/**
 * 待办详情 (无需权限)
 * @param {{string}} id
 * @returns
 */
export function todoInfo(id) {
  return service['api-scm']({
    url: '/public/activiti/purchaseInformation/info',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{id:number}} params
 * @description 导出采购合同
 */
export function exportContract(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/downloadPdf1',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{id:number}} params
 * @description 导出Excel格式的采购订单
 */
export function downloadExcel(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/downloadExcel',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @param {{id:number | string,path:string}} data
 * @description 导入
 */
export function importData(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/updatePdfAddress',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {Array<string|number>} ids
 * @description 对账
 */

export function reconciliation(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/reconciliation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 导出采购订单
 */
export function exportData(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/purchaseInformation/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}
/**
 * @param {{ids:string}}
 * @description 导出关闭的采购订单数据
 */
export function exportCloseData(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/purchaseInformation/exportCloseData',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true // 文件流是否通过浏览器下载
  })
}
/**
 *
 * @param {string} ids
 * @returns
 * @description  发货单打印
 */
export function procurementOrderShipInvoicePrint(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/shipInvoicePrint',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {string} ids
 * @returns
 * @description  配送清单打印
 */
export function jdInvoicePrinting(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/invoicePrinting',
    method: 'get',
    params: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 查看单个 sku 的历史价格
 * @param {{limit:number,page:number,productCode:string,supplierCode:string}} params
 * productCode 商品编码
 * supplier 供应商名称
 */
export function queryGoodsPurchaseHistory(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/selectHistoricalPrices',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function turnOffFin(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/closePushDownFinPayApply',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function reverseCloseFin(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/reverseClosePushDownFinPayApply',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/manualDockingOfKingdee',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 获取表尾统计数据
 * @param {object}
 */
export function getAmountSum(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/get/getAmountSum',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 整单关闭
 * @param {Array<number>} data
 */
export function orderWholeClose(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/orderWholeClose',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 整单反关闭
 * @param {Array<number>} data
 */
export function orderWholeReClose(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/orderWholeReClose',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 复制
 * @param {object} data
 */
export function duplicatePurchaseOrder(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/duplicatePurchaseOrder',
    method: 'post',
    data,
    tipTypes: 'none'
  })
}

/**
 * @description 块粘贴商品信息查询 -- 采购订单
 * @param {object} data
 */
export function blockPasteSearchProductInformationForPurchase(data) {
  return service['api-web']({
    url: '/public/product/blockPasteSearchProductInformationForPurchase',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{
 * date:Array<string>,
 * deptId:number,
 * enrollmentTime:string,
 * isEnable:(1|2),
 * keywords:string,
 * limit:number,page:number,
 * sort:string,workStatus:(1|2)}} params
 * @description  查询采购列表底部合计
 */
export function getTableSum(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/get/sumValue',
    method: 'get',
    params
  })
}

/**
 * @param {{
 * date:Array<string>,
 * deptId:number,
 * enrollmentTime:string,
 * isEnable:(1|2),
 * keywords:string,
 * limit:number,page:number,
 * sort:string,workStatus:(1|2)}} params
 * @description  采购订单重新生成销售订单
 */
export function getReCreateSaleOrder(params) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/save/reCreateSaleOrder',
    method: 'get',
    params
  })
}

/**
 * @param {{
 * ids:Array<string>,
 * @description  批量作废
 * }}
 */
export function batchCancellation(data) {
  return service['api-scm']({
    url: '/scm/purchase/purchaseInformation/batchCancellation',
    method: 'post',
    data
  })
}
