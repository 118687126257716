import { createUniqueString } from '@/utils/string'

/**
 * @file todo-tasks-handle
 * 本地创建首页子路由： 部分业务待办处理页
 * 1. 待办处理
 *    - 补货待办
 *    - 生产待办
 */

/**
 * 创建 特殊待办处理路由，添加到指定的 parentId 下
 * @see /api-web/public/getRoleMenu 模拟后端返回数据
 * @param {number} parentId
 * @param {object=} params
 * @returns {array}
 */
export default async (parentId, params) => {
  if (typeof parentId !== 'number') {
    console.error('【Create Router】Please specify "parentId"')
    return []
  }

  try {
    const tasksHandle = { id: createUniqueString(), pid: parentId, sort: 3 }
    return [
      {
        path: 'TasksHandle',
        icon: '',
        label: '待办处理',
        pmType: 1,
        pmUrl: '/local/home/tasksHandle',
        id: tasksHandle.id,
        pid: tasksHandle.pid,
        sort: tasksHandle.sort,
        breadcrumb: false,
        hidden: true,
        noTag: true,
        noCache: true
      },
      {
        path: 'purchaseReplenishment',
        label: '补货待办',
        pmType: 2,
        icon: '',
        pmUrl: '/local/home/tasksHandle/purchaseReplenishment',
        id: createUniqueString(),
        pid: tasksHandle.id,
        sort: 1,
        breadcrumb: false,
        hidden: true,
        noTag: true,
        noCache: true
      },
      {
        path: 'productionOrder',
        label: '生产待办',
        pmType: 2,
        icon: '',
        pmUrl: '/local/home/tasksHandle/productionOrder',
        id: createUniqueString(),
        pid: tasksHandle.id,
        sort: 2,
        breadcrumb: false,
        hidden: true,
        noTag: true,
        noCache: true
      },
      {
        path: 'batchDownloadImages',
        label: '商品图片批量下载申请待办',
        pmType: 2,
        icon: '',
        pmUrl: '/local/home/tasksHandle/batchDownloadImages',
        id: createUniqueString(),
        pid: tasksHandle.id,
        sort: 2,
        breadcrumb: false,
        hidden: true,
        noTag: true,
        noCache: true
      },

      {
        path: 'multipleTodoTaskManager/:id',
        label: '批量处理',
        pmType: 2,
        icon: '',
        pmUrl: '/local/home/tasksHandle/multipleTodoTaskManager',
        id: createUniqueString(),
        pid: tasksHandle.id,
        sort: 1,
        breadcrumb: true,
        hidden: false,
        noTag: false,
        noCache: false
      }
    ]
  } catch (error) {
    console.error(error)
  }
  return []
}
