/**
 * @module api-web:customer
 */
import service from '@/service'

/**
 * 客户信息 表查询
 * @param {{keywords:string,limit:number,page:number,sort:string}} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/data/clientInformation/getList',
    method: 'post',
    data
  })
}

/**
 * 客户信息 新增
 * @param {organization} data
 */
export function add(data) {
  return service['api-web']({
    url: '/data/clientInformation/save',
    method: 'post',
    data
  })
}

/**
 * 客户信息 编辑
 * @param {organization} data
 */
export function update(data) {
  return service['api-web']({
    url: '/data/clientInformation/updateAll',
    method: 'post',
    data
  })
}

/**
 * 客户信息 删除
 * @param {{ids:Array}} ids
 */
export function remove(params) {
  return service['api-web']({
    url: '/data/clientInformation/deleteCreateStatusClient',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/data/clientInformation/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 根据 ID 查询客户详情
 * @param {number} id
 */
export function info(id) {
  return service['api-web']({
    url: '/data/clientInformation/infoAll',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/clientInformation/infoAll',
    method: 'get',
    params: { id }
  })
}
/**
 * @param {object} params
 * @returns 审核驳回
 */
export function reject(data) {
  return service['api-web']({
    // url: '/data/clientInformation/reject',
    url: '/public/activiti/clientInformation/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 提交
 * @param {{ids:Array}} ids
 */
export function submit(ids) {
  return service['api-web']({
    url: '/data/clientInformation/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 启用禁用
 * @param {(string|number)} id - id
 * @param {(0|1)} status - 0:禁用 1:启用
 */
export function enableDisable(data) {
  return service['api-web']({
    url: '/data/clientInformation/updateDisableStatus',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 导出数据
 */
export function exportData(data) {
  return service['api-web']({
    url: '/data/clientInformation/clientInformation/export',
    method: 'post',
    data
    // responseType: 'blob', // 文件流接收类型声明转成 blob
    // saveAs: true, // 文件流是否通过浏览器下载
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} ids
 * @description 客户信息反审核
 */
export function rollback(ids) {
  return service['api-web']({
    url: '/data/clientInformation/reverseAudit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 客户信息反审核(批量)
 * @param {{ids:Array<string|number>}} data
 */
export function batchReverseAudit(data) {
  return service['api-web']({
    url: '/data/clientInformation/batchReverseAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-web']({
    url: '/data/clientInformation/manualDockingOfKingdee',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批量移动商品
 */
export function batchMoveProductCategory(data) {
  return service['api-web']({
    url: '/data/clientInformation/moveClientGroup',
    method: 'post',
    data
  })
}

/**
 * 客户列表树节点id
 */
export function getClientGroupInfo(params) {
  return service['api-web']({
    url: '/allPublic/getClientGroupInfo',
    crypt: { type: 'none' },
    method: 'get',
    params
  })
}

/**
 * 批改字段
 * @param {data} data
 * @returns
 */
export function markingParams(data) {
  return service['api-web']({
    url: '/data/clientInformation/batchUpdateClient',
    method: 'post',
    data
  })
}

/**
 * 批量启用
 * @param {data} data
 */
export function batchUpdateEnableStatus(data) {
  return service['api-web']({
    url: '/data/clientInformation/batchClientNoDisableStatus',
    method: 'post',
    data
  })
}

/**
 * 批量禁用
 * @param {data} data
 */
export function batchUpdateDisableStatus(data) {
  return service['api-web']({
    url: '/data/clientInformation/batchClientDisableStatus',
    method: 'post',
    data
  })
}

/**
 * @description 根据编码和类型获取操作日志
 */
export function getLog(params) {
  return service['api-web']({
    url: '/data/clientInformation/getLogsByCodeAndType',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
