/**
 * @module api-web:clientServe/sellOrder
 */
import service from '@/service'

/**
 * 合同订单 查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string
 * }} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/get/getList',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/**
 *
 * @param {(string|number)} code
 * @description 树形表格下拉
 */
export function getListDown(id) {
  return service['api-web']({
    url: '/sell/order/orderSale/get/getSaleOrderPullList',
    method: 'get',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function info(id) {
  return service['api-web']({
    url: '/sell/order/orderSale/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 待办详情 (无需权限)
 */
export function todoInfo(id) {
  return service['api-web']({
    url: '/public/activiti/orderSale/info',
    method: 'get',
    params: { id }
  })
}
/**
 * 创建
 * @param {object} data
 * @returns {object}
 */
export function create(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/save',
    method: 'post',
    data
  })
}

/**
 * 更新
 * @param {object} data
 * @returns {object}
 */
export function update(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/update',
    method: 'post',
    data
  })
}
/**
 * @param {Array<number|string>} ids
 * @description 提交
 */
export function submit(ids) {
  return service['api-web']({
    url: '/sell/order/orderSale/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} orderCodeList
 * @description 关闭
 */
export function close(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/wholeOrderClose',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {object} data
 */

export function reject(data) {
  return service['api-web']({
    // url: '/sell/order/orderSale/reject',
    url: '/public/activiti/orderSale/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{id:number | string,path:string}} data
 * @description 导入
 */
export function importData(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/saveContract',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{orderCode:string}}
 * @description 导出合同
 */
export function exportContract(params) {
  return service['api-web']({
    url: '/sell/order/orderSale/downloadPdf',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true, // 文件流是否通过浏览器下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {{orderCode:string}}
 * @description 导出报价单
 */
export function exportQuotation(params) {
  return service['api-web']({
    url: '/sell/order/orderSale/downloadExcelQuotation',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}
/**
 * @param {number} id
 * @description 反审核
 */
export function rollback(id) {
  return service['api-web']({
    url: '/sell/order/orderSale/reverseAudit',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-web']({
    url: '/sell/order/orderSale/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-web']({
    url: '/sell/order/orderSale/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object} data
 * @description 下推
 */
export function pushDown(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/generatedOrder',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}} data
 * 删除
 */
export function remove(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/delete',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {Array<string|number>} ids
 * @description 对账
 */

export function reconciliation(ids) {
  return service['api-web']({
    url: '/sell/order/orderSale/reconciliation',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{ids:string}}
 * @description 导出线下销售订单
 */
export function exportData(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/orderSale/export',
    method: 'post',
    data,
    responseType: 'blob' // 文件流接收类型声明转成 blob
  })
}

/**
 * @description 下载模板
 */
export function downloadExcel() {
  return service['api-web']({
    url: '/sell/order/orderSale/public/downloadExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {type: formData}
 * @description 导入销售订单
 */
export function importSaleOrderData(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/importSaleOrderData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 手动对接
 * @param {string} ids
 */
export function manualDockingOfKingdee(ids) {
  return service['api-web']({
    url: '/sell/order/orderSale/handButting',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 下载合同订单模版
 * @param {string}
 */
export function downloadContractExcel() {
  return service['api-web']({
    url: '/sell/order/orderSale/downloadContractExcel',
    method: 'post',
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: false, // 文件流是否通过浏览器下载， 下载的模板文件名需要有 buttonGroup 配置，所有不通过 axios 下载
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {type: formData}
 * @description 导入合同订单
 */
export function importContractOrderData(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/importContractOrderData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @param {number} businessState
 * @param {Array} orderIdList
 * @description 订单整单关闭-反关闭
 */
export function orderClose(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/orderClose',
    method: 'post',
    data
  })
}

/**
 * @description 获取所有启用的订单标记
 */
export function getAllEnableOrderMark(params) {
  return service['api-web']({
    url: '/sys/orderMark/get/getAllEnableOrderMark',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 销售订单编码推荐
 */
export function recommendSaleOrderCode(params) {
  return service['api-web']({
    url: '/allPublic/recommendSaleOrderCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 块粘贴商品信息查询 -- 采购订单
 * @param {object} data
 */
export function blockPasteSearchProductInformationForPurchase(data) {
  return service['api-web']({
    url: '/public/product/blockPasteSearchProductInformationForPurchase',
    method: 'post',
    data
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 批量反审核订单
 */
export function batchReverseAudit(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/batchReverseAudit',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    tipTypes: 'none'
  })
}

export function getPdfInfo(params) {
  return service['api-web']({
    url: '/sell/order/orderSale/downloadPdf',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 异步获取对应组织下拉框(货主)  (财务付款组织，结算组织都用这个，与祥威确定）
 */
export function getCompanyInfoOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyInfo',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{
 * ids:Array<string>,
 * @description  批量作废
 * }}
 */
export function batchCancellation(data) {
  return service['api-web']({
    url: '/sell/order/orderSale/batchCancellation',
    method: 'post',
    data
  })
}
