<template>
  <div
    v-loading="loading || layouting"
    :element-loading-text="loadingText"
    :element-loading-spinner="loadingIcon"
    :element-loading-background="loadingBgColor"
    :class="['app-container', fixedHeight ? 'fixed-height' : '']"
  >
    <CustomQueryTab
      v-if="showCustomQueryTab"
      ref="CustomQueryTabRef"
      :custom-query-tab-key="customQueryTabKey"
      @tab-search-event="tabSearchEvent"
      @tab-search-event-default="tabSearchEventDefault"
    />
    <slot name="custom-query-tab" :scope="$parent" />
    <TitleBar
      v-if="!hideTitleBar"
      ref="titleBarRef"
      :hide-tit="hideTitleText"
      :cancel-style="cancelStyle"
      :text="_titleBar.text"
      :support-expand-collapse="_titleBar.supportExpandCollapse"
      @expand-collapse="onExpandCollapse"
    >
      <template #default>
        <div class="filter-query-form-container">
          <slot name="filter-form" :scope="$parent" />
        </div>
        <div class="filter-query-form-operations">
          <slot name="filter-buttons" :scope="$parent" />
          <span v-if="showCustomQueryTab" class="custom_search_btn">
            <el-button @click="createCustomSearchTab">生成筛选方案</el-button>
            <el-button @click="editCustomSearchTab">方案管理</el-button>
          </span>
        </div>
      </template>
    </TitleBar>
    <!-- detail-operation -->
    <slot name="detail-operation" :scope="$parent" />

    <MainContent
      :height-control="heightControl"
      :gutter="0"
      :left-span="leftSpan"
      :right-span="rightSpan"
    >
      <!-- left: tree-navs,... -->
      <template #left>
        <slot name="left" :scope="$parent" />
      </template>
      <!-- right: tqable-operations ,table ,...-->
      <template #right>
        <slot name="right" :scope="{ ...$parent, tableHeight }" />
      </template>
      <!-- detail-form -->
      <template #detail-form>
        <slot name="detail-form" :scope="$parent" />
      </template>
    </MainContent>
    <slot :scope="$parent" />
    <!-- @see bug:5864 将审核图标移至DetailOperationBar组件中，保证图标跟随按钮的位置 -->
    <!-- <svg-icon
      v-if="aduitIconIsShow"
      style="
        position: absolute;
        right: 50px;
        top: 80px;
        width: 100px;
        height: 100px;
        z-index: 1001;
      "
      icon-class="i-aduit"
    /> -->
    <!-- 新增方案 -->
    <el-dialog
      title="生成筛选方案"
      :visible.sync="customFilterVisible"
      width="35%"
    >
      <el-form
        ref="customFilterFormRef"
        :model="customFilterForm"
        label-width="80px"
        :rules="customFilterRules"
      >
        <el-form-item label="方案名称" prop="solutionName">
          <!-- 由于使用v-model会出现输入框卡顿 当前没有合适办法解决 -->
          <!-- 此处使用原生input 自行实现数据的双向绑定 -->
          <input class="noModelInputRef" @input="noModelInputChange" />
        </el-form-item>
        <el-form-item label="是否默认" prop="isDefault">
          <el-radio-group v-model="customFilterForm.isDefault">
            <el-radio label="是" border style="width: 73px" />
            <el-radio label="否" border />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="方案属性" prop="isShared">
          <el-radio-group v-model="customFilterForm.isShared">
            <el-radio label="公用" border />
            <el-radio label="个人" border />
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customFilterVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCreateCustomSearch">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <!-- 方案管理  -->
    <el-dialog
      title="方案管理"
      :visible.sync="searchTabManageVisible"
      width="35%"
      class="searchTabManage"
      @close="confirmUpdateCustomSearch"
    >
      <div style="min-height: 100px">
        <div class="searchTabManageBtn">
          <el-button type="primary" @click="defaultSearchTab">
            <svg-icon icon-class="i-system-tag" />
            设为默认
          </el-button>
          <el-button icon="el-icon-delete" @click="deleteManage">
            删除
          </el-button>
        </div>
        <div v-loading="customQueryLoading" class="manage_list_container">
          <div
            v-for="item in preSetSolutionList"
            :key="item.id"
            :class="[
              'manage_list',
              clickNowId === item.id ? 'manage_list_active' : ''
            ]"
            @click="preSetSolutionListBtn(item.id, item)"
          >
            <div style="display: flex; align-items: center">
              <el-tag v-if="item.isShared" type="warning">公用</el-tag>
              <el-tag v-else>个人</el-tag>
              <span class="show_manage_title" :title="item.solutionName">
                {{ item.solutionName }}
              </span>
            </div>
            <el-button v-if="item.isDefault" type="primary" plain>
              默认
            </el-button>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmUpdateCustomSearch">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateCustomSearch">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import poll from '@/utils/poll'
import { debounce } from '@/utils'
import { isHTMLElement } from '@/utils/dom'
import getStyle from '@/utils/get-style'
import TitleBar from '@/components/TitleBar'
import MainContent from '@/components/MainContent'
import { isNumeric, isString } from '@/utils/validate'
import { POLL_TIMEOUT, DONOT_POLL_LAYOUT_ROUTENAMES } from '@/constants'
import CustomQueryTab from '@/components/CustomQueryTab'
import {
  addCustomQuery,
  getCustomQuery,
  deleteCustomQuery,
  setDefaultCustomQuery
} from '@/service/api-scm/index.js'
import { cloneDeep } from 'lodash'

export default {
  name: 'BaseContainer',
  components: {
    TitleBar,
    MainContent,
    CustomQueryTab
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Loading...'
    },
    loadingIcon: {
      type: String,
      default: ''
    },
    loadingBgColor: {
      type: String,
      default: ''
    },
    titleBar: {
      type: Object,
      default: () => ({
        text: '',
        supportExpandCollapse: true
      })
    },
    hideTitleBar: {
      type: Boolean,
      default: false
    },
    useContentHeight: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    leftSpan: {
      type: Number,
      default: 5
    },
    rightSpan: {
      type: Number,
      default: 19
    },
    // 详情页标题后的编码，比如产品详情-9897675
    titleBarCode: {
      type: String,
      default: ''
    },
    showCustomQueryTab: {
      type: Boolean,
      default: false
    },
    customQueryTabKey: {
      type: String,
      default: ''
    },
    solutionJson: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      initRefsDone: false,
      layouting: false,
      hasMounted: false,
      $dom: null,
      $appmain: null,
      $titbar: null,
      $content: null,
      $tableOperation: null,
      tableOperationMargin: '16px',
      $tablePagination: null,
      contentHeight: 'auto', // 列表页：除筛选表单外区域高度；详情页：除详情操作栏外表单表格区域高度
      scrollBarWidth: 7, // 表格的滚筒条参与高度 see src/styles/mixin.scss @mixin scrollbar
      tableTabsHeight: 0, // 列表页：表格的切换 tab
      tableOperationHeight: 0, // 列表页：表格操作栏高度
      tablePaginationHeight: 0, // 列表页：表格分页高度
      tableHeadHeight: 0, // 列表页：表格表头高度
      tableRowHeight: 0, // 列表页：表格单行高度
      resizeHandler: null,
      initError: false,
      tableHeight: 'auto',
      // aduitIconIsShow: false,
      // 除了详情页还需要取掉标题页面
      cancelTitleViews: [
        '/financialAffairs/accountReceivable/summary/',
        '/financialAffairs/accountReceivable/analyze/',
        '/dockCenter/kingdee/',
        '/financialAffairs/accountReceivable/report/',
        '/financialAffairs/accountReceivable/receiptManualOff/',
        '/financialAffairs/accountReceivable/estimateManualOff',
        '/financialAffairs/accountPayable/receiptManualOn/',
        '/financialAffairs/accountPayable/finApStatements/',
        '/financialAffairs/generalLedger/voucherManagement/generateVoucher/',
        '/financialAffairs/generalLedger/endOfPeriod/finProfitLossConvert/',
        '/taskCenter/queryListByPage/',
        '/supplyChain/orderManage/scanInspection/',
        '/system/department',
        '/system/dictionary',
        '/system/job',
        '/system/permission',
        '/system/log',
        '/system/process/category',
        '/system/process/todo',
        '/department',
        '/taskCenter/page'
      ],
      // 去掉没有标题页面的样式
      cancelStyle: false,
      customFilterVisible: false,
      customFilterForm: {
        solutionName: '',
        solutionJson: null,
        pageType: '',
        isShared: 0,
        isDefault: 0,
        id: null
      },
      customFilterRules: {
        solutionName: [
          { required: true, message: '请输入方案名称', trigger: 'blur' },
          { max: 50, message: '方案名称长度在50个字符以内', trigger: 'blur' }
        ],
        isDefault: [
          { required: true, message: '请选择是否默认', trigger: 'blur' }
        ],
        isShared: [
          { required: true, message: '请选择方案属性', trigger: 'blur' }
        ]
      },
      searchTabManageVisible: false,
      preSetSolutionList: [],
      clickNowId: null,
      customQueryLoading: false,
      clickNowItem: {}
    }
  },

  computed: {
    ...mapState({
      contentInnerGap: (state) => state.settings.contentInnerGap,
      contentTotalInnerGap: (state) => state.settings.contentTotalInnerGap,
      contentTotalOuterGap: (state) => state.settings.contentTotalOuterGap,
      totalNavTagHeight: (state) => state.settings.totalNavTagHeight,
      updateContentHeightFlag() {
        return !!(
          (this.$pageType.isListPage(this.$route.name) ||
            this.useContentHeight) ??
          false
        )
      }
    }),
    _titleBar() {
      let text = this.$route.meta.title || ''

      const code = this.titleBarCode
      if (text && code) {
        text = text + ' - ' + code
      }

      return {
        supportExpandCollapse: true,
        ...this.titleBar,
        text: this.titleBar.text || text
      }
    },
    /**
     * @returns {boolean} - 是否固定高度
     */
    fixedHeight() {
      // eslint-disable-next-line no-unused-vars
      const { fullPath, name, meta } = this.$route
      const { fixedHeight = !this.$pageType.isDetailPage(name) } = meta
      return fixedHeight
    },
    /**
     * @returns {{height?:string,minHeight?:string}}
     */
    heightControl() {
      // 固定高度
      const fixed = {
        height: this.contentHeight
      }
      // 流式布局
      const flow = {
        minHeight: this.contentHeight
      }
      return this.fixedHeight
        ? fixed
        : this.contentHeight !== 'auto'
        ? flow
        : fixed
    },
    /**
     * @returns {boolean} - 是否隐藏标题，嵌套在 el-dialog,el-drawer 中，默认隐藏页面标题，另外，props: `hideTitle` 支持配置
     */
    hideTitleText() {
      // 除了详情页面去掉标题
      const routerInfo = this.cancelTitleViews.filter((v) =>
        this.$route.path.includes(v)
      )
      if (
        this.$route.path.split('/').includes('list') ||
        (routerInfo && routerInfo.length > 0)
      ) {
        return true
      }

      try {
        return (
          this.$parent?.$el?.className === 'el-dialog__wrapper' ||
          this.$parent?.$el?.className === 'el-drawer__wrapper' ||
          this.hideTitle === true
        )
      } catch (error) {
        console.error(error)
      }
      return false
    },
    filterQueryFormOperationsPadding() {
      return this.$pageType.isListPage(this.$route.name) ? '6px' : '0px'
    }
  },
  created() {
    // this.showAduitIcon()
    const filterSlots = Object.keys(this.$slots).filter(
      (v) => v !== 'left' && v !== 'right'
    )
    if (filterSlots.length === 0) {
      this.cancelStyle = true
    }
  },
  activated() {
    this.init()
    // this.showAduitIcon()
  },
  async mounted() {
    await this.init()
  },
  beforeMount() {
    this.bindResize()
  },
  beforeDestroy() {
    this.unbindResize()
  },
  methods: {
    async init() {
      // Boundary case, if the slot is not installed, poll for {{POLL_TIMEOUT.slotContent}} seconds
      const $el = await poll({
        target: this,
        nested: ['$el'],
        timeout: POLL_TIMEOUT.slotContent
      })
      if (!isHTMLElement($el)) {
        this.initError = true
        return void console.error('error $dom of app-container')
      }
      this.$dom = $el
      this.doLayout()
      this.hasMounted = true
    },
    initRefs() {
      try {
        if (this.initRefsDone === true) return
        this.$appmain =
          this.$dom?.parentElement ?? this.$dom?.parentNode ?? null
        this.$titbar = this.$dom?.querySelector('.app-container > .title-bar')
        this.$content = this.$dom?.querySelector('.app-container > .content')
        this.$tableTabs = this.$content?.querySelector('.table-tabs')
        this.$tableOperation =
          this.$content.querySelector('.table-operation') ??
          this.$content?.querySelector(
            '.right > .base-inline-edit-table > .vxe-toolbar'
          ) ??
          null
        this.tableOperationType = this.$tableOperation
          ? this.$tableOperation.className === 'table-operation'
            ? 'custom'
            : this.$tableOperation.className === 'vxe-toolbar'
            ? 'vxe-toolbar'
            : 'unknown'
          : 'none'

        this.$tablePagination = this.$content?.querySelector(
          '.pagination-container'
        )

        this.initRefsDone = true
      } catch (error) {
        console.error(error)
      }
    },

    bindResize() {
      if (this.initError) {
        return void console.error('init error')
      }
      try {
        this.resizeHandler = debounce(() => void this.doLayout(), 320)
        window.addEventListener('resize', this.resizeHandler)

        if (
          !['SupplyChainProcurementManagePurchaseOrderList'].includes(
            this.$route.name
          )
        ) {
          this.$store.dispatch('setGlobalResizeHandler', () => {
            void this.doLayout({ showLoading: false })
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    unbindResize() {
      try {
        if (typeof this.resizeHandler === 'function') {
          window.removeEventListener('resize', this.resizeHandler)
          this.resizeHandler = null
        }
      } catch (error) {
        console.error(error)
      }
    },
    async doLayout(options = { showLoading: true }) {
      console.log('dolayout')

      if (options?.showLoading === true) this.layouting = true
      try {
        this.initRefs()
        await this.$halt(120)
        await this.updateContentHeight()
        await this.updateTableHeight()
        // await this.$halt(160)
      } catch (e) {} // eslint-disable-line no-trailing-spaces, no-empty
      if (this.layouting === true) this.layouting = false

      console.log('the end time of this render:', performance.now())
    },
    onExpandCollapse(e) {
      this.doLayout({ showLoading: false })
    },
    /**
     * @description 列表页面自动更新高度
     */
    async updateContentHeight() {
      try {
        if (this.updateContentHeightFlag === true) {
          const VH = document.body.getBoundingClientRect().height
          const TOP_H = this.totalNavTagHeight
          console.groupCollapsed('【updateContentHeight】(part1)')
          console.log('100vh=', VH)
          console.log(
            "appmain's padding-top (navbar height && tagbar height):",
            TOP_H
          )
          const titleBarHeight =
            this.$titbar?.getBoundingClientRect()?.height ??
            (this.$titbar?.querySelector('.title-bar')
              ? parseFloat(
                  getStyle(
                    this.$titbar?.querySelector('.title-bar'),
                    'height'
                  ) || '0px',
                  10
                )
              : 0)
          const TIT_H = isNumeric(titleBarHeight) ? titleBarHeight : 0
          console.log("titlebar's height:", TIT_H)
          const contentHeight = VH - TOP_H - TIT_H - this.contentTotalOuterGap
          this.contentHeight =
            isNumeric(contentHeight) && contentHeight > 0
              ? `${contentHeight * 1}px`
              : 'auto'
          console.log("content's height:", contentHeight, this.contentHeight)
          console.groupEnd()
          /**
           * 获取表格标签切换栏高度、操作栏高度以及表格分页高度，从而实现固定表格高度，以达到固定表头的目的
           */
          console.groupCollapsed('【updateContentHeight】(part2)')
          // 表格标签切换栏
          const tableTabsHeight =
            this.$tableTabs?.getBoundingClientRect()?.height ?? 0
          this.tableTabsHeight = tableTabsHeight
          console.log('table tabs height:', tableTabsHeight)

          // 表格操作栏高度
          const tableOperationHeight =
            this.$tableOperation?.getBoundingClientRect()?.height ?? 0
          console.log('table operation height:', tableOperationHeight)
          // 表格操作栏的下外边距
          const tableOperationMargin =
            tableOperationHeight > 0
              ? this.tableOperationType === 'custom'
                ? 16
                : 0
              : 0
          console.log('table operation margin-bottom:', tableOperationMargin)
          this.tableOperationMargin = `${tableOperationMargin}px`
          // 表格操作栏整体占用高度
          this.tableOperationHeight =
            tableOperationHeight + tableOperationMargin
          console.log(
            'table operation height (include margin-bottom):',
            this.tableOperationHeight
          )
          //  表格分页栏高度
          const tablePaginationHeight =
            this.$tablePagination?.getBoundingClientRect()?.height ?? 0
          console.log('table pagination height:', tablePaginationHeight)
          // 表格分页栏的上外边距
          const tablePaginationMargin = tablePaginationHeight > 0 ? 20 : 0
          console.log('table pagination margin-top:', tablePaginationMargin)
          // 表格分页栏整体扎农高度
          this.tablePaginationHeight =
            tablePaginationHeight + tablePaginationMargin
          console.log(
            'table pagination height (include margin-top):',
            this.tablePaginationHeight
          )

          const [tableHeadHeight, tableRowHeight, tableRowsNum = 0] =
            await Promise.all(
              [
                this.getEleHeight(
                  document,
                  '.app-container .split-layout .right table thead',
                  0
                ),
                this.$store.state.currentHasListData
                  ? this.getEleHeight(
                      document,
                      '.app-container .split-layout .right table tbody tr',
                      0
                    )
                  : null,
                Promise.resolve(
                  this.$content?.querySelector('.el-table__body-wrapper')
                    ? this.$content?.querySelectorAll(
                        '.el-table__body-wrapper table tbody tr'
                      )?.length
                    : this.$content
                        ?.querySelector('.vxe-table--main-wrapper')
                        ?.querySelectorAll(
                          '.vxe-table--body-wrapper table tbody tr'
                        )?.length
                )
              ].filter((v) => !!v)
            )
          // 表头高度
          this.tableHeadHeight = tableHeadHeight
          console.log('table head height:', tableHeadHeight)
          // 表格单行高度
          this.tableRowHeight = tableRowHeight
          console.log('table row height:', tableRowHeight)
          // 表格行数量
          this.tableRowsNum = tableRowsNum
          console.log('table rows number:', this.tableRowsNum)
          console.groupEnd()
        }
      } catch (error) {
        console.error(error)
        this.contentHeight = 'auto'
      } finally {
        console.groupEnd()
      }
    },
    /**
     * @return {string} - table height of list page , css: 'auto' or height px str
     */
    updateTableHeight() {
      console.groupCollapsed('【updateTableHeight】')
      if (!this.contentHeight || this.contentHeight === 'auto') {
        this.tableHeight = 'auto'
        console.warn('table height [1]:', this.tableHeight)
        return void console.groupEnd()
      }

      const total = parseFloat(this.contentHeight, 10)
      const tabs = this.tableTabsHeight
      const operation = this.tableOperationHeight
      const pagination = this.tablePaginationHeight

      /**
       * @type {nubmer}
       * @description 根据表头高度、表格行高和表格行数计算出的表格高度
       */
      const tableSelf =
        this.tableHeadHeight +
          this.tableRowHeight * this.tableRowsNum +
          this.scrollBarWidth || 0

      /**
       * @type {nubmer}
       * @description 根据内容区高度、表格操作栏、分也栏等减法计算出的表格高度
       * 12 此次边距共缩短的像素
       */
      const tableComputed =
        total -
          tabs -
          operation -
          pagination -
          this.contentTotalInnerGap -
          this.scrollBarWidth +
          12 || 0

      if (
        !(isNumeric(tableSelf) && tableSelf * 1 > 0) &&
        !(isNumeric(tableComputed) && tableComputed * 1 > 0)
      ) {
        this.tableHeight = this.contentHeight
        console.warn('table height [2]:', this.tableHeight)
        return void console.groupEnd()
      }

      const tableHeight =
        tableSelf > tableComputed
          ? `${Math.min(tableSelf, tableComputed)}px`
          : `${Math.max(tableSelf, tableComputed)}px`
      console.log('表格数据本需表格高度:', tableSelf)
      console.log('内容区域反推表格高度:', tableComputed)
      console.log('表格高度取值:', tableHeight)
      this.tableHeight = tableHeight
      console.groupEnd()
    },
    /**
     * 获取元素本身高度加元素的样式高度
     * @param {any} $domScope - HTML element
     * @param {string} selector css query selector
     * @param {(string|number)=} incremental
     * @returns {number} - sum:element height + incremental, incremental(margin,padding,...)
     */
    async getEleHeight($domScope, selector, incremental = 0) {
      try {
        if (!isString(selector)) {
          console.error('error "selelctor" for "getEleHeight"', selector)
          return 0
        }

        let $ele = null
        if ($domScope) {
          if (!DONOT_POLL_LAYOUT_ROUTENAMES.includes(this.$route.name)) {
            await poll({
              target: () => !!$domScope.querySelector(selector),
              timeout: POLL_TIMEOUT.request,
              expect: true
            })
          } else {
            await poll({
              target: () => this.hasMounted === true,
              timeout: POLL_TIMEOUT.request,
              expect: true
            })
          }

          $ele = $domScope.querySelector(selector)

          if (!isHTMLElement($ele)) {
            console.warn(
              'losed "$ele" that $domScope query',
              $domScope,
              selector,
              document.querySelector(selector),
              $ele
            )
          }
        } else {
          console.warn(
            `losed "$domScope", docuement 's element not ready`,
            $domScope,
            selector
          )
        }

        const height = $ele
          ? Math.ceil($ele?.getBoundingClientRect()?.height || 0)
          : 0

        const _incremental = isNumeric(incremental) ? incremental * 1 : 0

        return height + _incremental
      } catch (error) {
        console.error('"getEleHeight" error', error)
        return 0
      }
    },
    tabSearchEvent(params) {
      this.$emit('tab-search-event', params)
    },
    tabSearchEventDefault(params) {
      this.$emit('tab-search-event-default', params)
    },
    createCustomSearchTab() {
      this.customFilterVisible = true
      this.customFilterForm.isDefault = '否'
      this.customFilterForm.isShared = '个人'
      this.customFilterForm.solutionName = null
      this.$nextTick(() => {
        const noModelInputRef =
          document.getElementsByClassName('noModelInputRef')
        noModelInputRef[0].value = null
      })
    },
    confirmCreateCustomSearch() {
      this.$refs.customFilterFormRef.validate(async (valid) => {
        if (valid) {
          const params = {
            id: null,
            pageType: this.customQueryTabKey,
            solutionName: this.customFilterForm.solutionName,
            isShared: this.customFilterForm.isShared === '个人' ? 0 : 1,
            isDefault: this.customFilterForm.isDefault === '否' ? 0 : 1,
            solutionJson: JSON.stringify(this.solutionJson)
          }

          const { code } = await addCustomQuery(params)
          if (code === 200) {
            this.$message.success('方案新增成功')
            this.customFilterVisible = false
            this.$refs.CustomQueryTabRef.getCustomQueryList({
              isCreated: false
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    confirmUpdateCustomSearch() {
      this.$refs.CustomQueryTabRef.getCustomQueryList({ isCreated: false })
      this.searchTabManageVisible = false
    },
    async getCustomQueryList() {
      this.customQueryLoading = true
      // 载荷
      const params = {
        pageType: this.customQueryTabKey
      }
      const { code, data } = await getCustomQuery(params)
      if (code === 200) {
        const { defaultSolution, preSetSolutionList } = data
        this.preSetSolutionList = preSetSolutionList
        if (defaultSolution) {
          this.preSetSolutionList.unshift(defaultSolution)
        }
      }
      this.customQueryLoading = false
    },
    editCustomSearchTab() {
      this.getCustomQueryList()
      this.searchTabManageVisible = true
    },
    preSetSolutionListBtn(id, item) {
      this.clickNowId = id
      this.clickNowItem = item
    },
    async deleteManage() {
      if (!this.clickNowId) {
        return this.$message.error('请先选择方案')
      }
      this.$confirm('是否确定删除方案?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const params = {
            id: this.clickNowId,
            pageType: this.customQueryTabKey
          }
          const { code } = await deleteCustomQuery(params)
          if (code === 200) {
            this.$message.success('删除成功')
            this.getCustomQueryList()
            this.clickNowId = null
            this.clickNowItem = {}
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    async defaultSearchTab() {
      if (!this.clickNowId) {
        return this.$message.error('请先选择方案')
      }
      const nowItem = cloneDeep(this.clickNowItem)
      console.log('nowItem', nowItem)

      // delete nowItem.isDefault
      const params = {
        id: nowItem.id
      }
      const { code } = await setDefaultCustomQuery(params)
      if (code === 200) {
        this.$message.success('方案默认成功')
        this.getCustomQueryList()
      }
    },
    /**
     * 实现自定义数据双向绑定
     */
    noModelInputChange(e) {
      const noModelInputRef = document.getElementsByClassName('noModelInputRef')
      const text = noModelInputRef[0].value
      this.customFilterForm.solutionName = text
    },
    updateHeightEvent() {
      this.$refs?.['titleBarRef']?.updateHeightEvent()
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.filter-query-form-operations) {
  // padding-top: v-bind(filterQueryFormOperationsPadding);
  display: flex;
  .custom_search_btn {
    margin-left: 10px;
  }
}
:deep(.table-operation) {
  margin-bottom: v-bind(tableOperationMargin);
}
.manage_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 5px 20px;
  transition: padding 0.1s ease, background-color 0.1s ease, color 0.1s ease; /* 添加过渡效果 */
  &:hover {
    background-color: #e8f4ff;
    cursor: pointer;
    padding: 5px 22px;
    color: #000;
  }
}
.manage_list_active {
  background-color: #e8f4ff;
  padding: 5px 22px;
}
.searchTabManageBtn {
  margin: 5px 20px;
}
.searchTabManage {
  :deep(.el-dialog__body) {
    padding: 0;
  }
}
.manage_list_container {
  max-height: 352px;
  overflow-y: auto;
}
.show_manage_title {
  width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 28px;
  height: 28px;
  margin-left: 5px;
}
:deep(.el-icon-delete) {
  color: red;
}
.noModelInputRef {
  height: 28px;
  width: 100%;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
}
.noModelInputRef:focus-visible {
  height: 28px;
  width: 100%;
  border: 1px solid #1890ff;
  border-radius: 3px;
  outline: none;
}
</style>

<docs lang="md">
This base container is base layout for list page or detail page

### Useage

```html
<template>
  <base-container>
    <!-- use for list page layout -->
    <template #filter-form>
      <!-- 1. filter query form -->
    </template>
    <template #filter-buttons>
      <!-- 2. filter query form's buttons -->
    </template>
    <template #left>
      <!-- 3. tree/dir nav , if have -->
    </template>
    <template #right="{scope}">
      <!-- 4. table-operations-bar and table -->
    </template>

    <!-- use for detail page layout -->
    <template #detail-operation="{scope}">
      <!-- 1. detail operation bar -->
    </template>
    <template #detail-form>
      <!-- 2. form -->
    </template>
  </base-container>
</template>
```
</docs>
