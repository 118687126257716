var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',[_c('div',{staticClass:"form-part"},[(_vm.useTabDisplay)?_c('FullTabs',{ref:"full-tabs",attrs:{"layout":_vm.layout,"tabs":_vm.tabs,"titles":_vm.titles,"show-handle-form":_vm.showHandleForm,"default-form-model":_vm.defaultFormModel,"show-table-alone":_vm.showTableAlone,"form-items":_vm.proxyFormItems,"table-header":_vm.tableHeader,"process-id":_vm.processId,"document-code":_vm.documentCode,"process-submitter":_vm.processSubmitter,"dynamic-form-label":_vm.dynamicFormLabel},on:{"change-form":_vm.onChangeForm}}):[(_vm.layout === 'form')?_c('div',{staticClass:"the-form"},[(_vm.showFormTitle)?_c('BlueTitleText',{attrs:{"title-name":_vm.titles.form}}):_vm._e(),_c('agel-form',{staticClass:"agel-input-audit-reset",attrs:{"attach":{ items: _vm.proxyFormItems }},model:{value:(_vm.model.form),callback:function ($$v) {_vm.$set(_vm.model, "form", $$v)},expression:"model.form"}})],1):(_vm.layout === 'table')?_c('div',{staticClass:"the-table"},[(_vm.showTableTitle)?_c('BlueTitleText',{attrs:{"title-name":_vm.titles.table}}):_vm._e(),_c('base-inline-edit-table',{ref:"backlog-audit-process-node-table",attrs:{"table-key":"backlog-audit-process-node-table","drag-column-key":"BacklogAuditTab","crud-local":['read'],"has-tool-bar":false,"disable-operate":true,"table-header":_vm.tableHeader,"table-loading":_vm.model.table.loading,"value":_vm.model.table.data,"cell-placeholder":"⏤","external-class":"backlog-audit-biet"},on:{"update:tableLoading":function($event){return _vm.$set(_vm.model.table, "loading", $event)},"update:table-loading":function($event){return _vm.$set(_vm.model.table, "loading", $event)}},scopedSlots:_vm._u([{key:"stateDefault",fn:function({ row }){return [_c('LabelPickerColorCircleMark',{attrs:{"dict":"sys.processNodeStatus","value":row.state?.value,"color":{
                1: 'danger', // 驳回
                0: 'warning', // 当前
                '-1': 'success' // 完成
              }}})]}},{key:"durationDefault",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row?.duration))])]}}],null,false,2512145569)})],1):(_vm.layout === 'diagram')?_c('div',{staticClass:"the-diagram"},[(_vm.showDiagramTitle)?_c('BlueTitleText',{attrs:{"title-name":_vm.titles.diagram}}):_vm._e(),_c('ProcessDesign',_vm._b({attrs:{"instance-id":_vm.processId,"document-code":_vm.documentCode,"process-submitter":_vm.processSubmitter}},'ProcessDesign',_vm.model.diagram,false))],1):_c('div',[_vm._v("Not Support Layout")])]],2),(_vm.showTableAlone)?_c('div',{staticClass:"form-part the-table padding-bottom",class:{ 'is-collapse': !_vm.isExpand }},[_c('BlueTitleText',{attrs:{"title-name":_vm.titles.table,"show-expand-button":""},on:{"toggle":_vm.onToggleExpand}}),_c('base-inline-edit-table',{ref:"backlog-audit-process-node-table",attrs:{"table-key":"backlog-audit-process-node-table","drag-column-key":"BacklogAuditTab","crud-local":['read'],"has-tool-bar":false,"disable-operate":true,"table-header":_vm.tableHeader,"table-loading":_vm.model.table.loading,"value":_vm.model.table.data,"cell-placeholder":"⏤","external-class":"backlog-audit-biet"},on:{"update:tableLoading":function($event){return _vm.$set(_vm.model.table, "loading", $event)},"update:table-loading":function($event){return _vm.$set(_vm.model.table, "loading", $event)}},scopedSlots:_vm._u([{key:"stateDefault",fn:function({ row }){return [_c('LabelPickerColorCircleMark',{attrs:{"dict":"sys.processNodeStatus","value":row.state?.value,"color":{
            1: 'danger', // 驳回
            0: 'warning', // 当前
            '-1': 'success' // 完成
          }}})]}},{key:"durationDefault",fn:function({ row }){return [_c('span',[_vm._v(_vm._s(row?.duration))])]}}],null,false,4017159841)})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }