/**
 * @module api-web:doc/index
 * @description api for data/goods
 */
import service from '@/service'

/**
 * 查询商品列表 测试可删除的
 * @param {{companyId:number,thirdPartyCompanyId:number}} params
 */
export function getList(data) {
  return service['api-web']({
    url: '/data/product/Information/getList',
    method: 'post',
    data
  })
}
