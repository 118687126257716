var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',_vm._g(_vm._b({directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.uuTableKey,ref:"base-table",class:[
    'base-table',
    'base-el-table',
    _vm.columnWidthResizable
      ? [
          'column-width-resizable',
          _vm.$attrs?.border !== true ? 'hide-border' : ''
        ]
      : '',
    _vm.isChildrenSelectHidden ? 'table-children-select' : '',
    ..._vm.externalClasses
  ],style:(_vm.extraStyle),attrs:{"size":_vm.getSize,"data":_vm.data,"row-key":_vm.rowKey,"height":_vm.tableHeight,"max-height":_vm.maxHeight || _vm.tableHeight,"highlight-current-row":_vm.highlightCurrentRow,"header-cell-style":{ 'text-align': 'left' },"cell-style":{ 'text-align': 'left' },"header-cell-class-name":_vm.headerClassName,"load":_vm.loadChildren,"row-style":_vm.rowStyle},on:{"selection-change":_vm._selectionChange,"header-dragend":_vm.onHeaderDragend,"cell-mouse-enter":_vm.handleRowMouseEnter,"cell-mouse-leave":_vm.handleRowMouseLeave,"cell-contextmenu":_vm.handleCellContextmenu,"sort-change":_vm.onSortChange,"filter-change":_vm.filterChange}},'el-table',_vm.proxyProps,false),_vm.$listeners),[_vm._t("expand"),(_vm.hasIndex)?_c('el-table-column',{attrs:{"type":"index","label-class-name":"ignore-elements","prop":"index","label":"序号","align":"center","width":_vm.tableHeader.find((item) => item.prop === 'index')?.width || 'auto',"show-overflow-tooltip":"","min-width":30,"fixed":"left","render-header":_vm.adaptationWidth}}):_vm._e(),_vm._t("selection"),_vm._l((_vm.tableHeader),function(item){return [(item.type)?_c('el-table-column',{key:(item.label ?? item.type) + item.prop,attrs:{"label":item.label,"prop":item.prop || item.type,"type":item.type,"fixed":"left","show-overflow-tooltip":"","reserve-selection":_vm.reserve,"min-width":30}}):_c('el-table-column',_vm._b({key:item.uuid,attrs:{"type":item.type,"label":item.label,"prop":item.prop || item.type,"fixed":item.fixed,"width":item.width || 'auto',"align":item.align || 'center',"min-width":30,"max-width":item.maxWidth,"sortable":typeof item.sortable === 'boolean' ? item.sortable : false,"show-overflow-tooltip":typeof item.showOverflowTooltip === 'boolean'
          ? item.showOverflowTooltip
          : true,"render-header":(h, { column, $index }) =>
          _vm.adaptationWidth(h, { column, $index }, item)},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(
            (typeof item.autoCopy === 'function'
              ? item.autoCopy(row)
              : item.autoCopy) &&
            _vm.hoveredRow === row &&
            row[item.prop]
          )?_c('svg-icon',{staticClass:"copySvg",staticStyle:{"cursor":"pointer"},attrs:{"icon-class":"i-copy-hollow"},on:{"click":function($event){$event.stopPropagation();return _vm.goHandleCopy(row[item.prop])}}}):_vm._e(),(
            item.isComp &&
            (typeof row[item.prop] !== 'undefined' || row[item.prop] !== null)
          )?_vm._t(item.prop,null,{"row":Object.assign(row, {
              [item.prop]:
                typeof item.formatter === 'function'
                  ? item.formatter(row)
                  : row[item.prop]
            })}):(
            (typeof row[item.prop] === 'string' &&
              row[item.prop] !== '' &&
              row[item.prop].trim()) ||
            typeof row[item.prop] === 'number'
          )?_c('span',{class:[item.prop === 'index' ? 'customer-index' : '']},[_vm._v(" "+_vm._s(typeof item.formatter === 'function' ? item.formatter(row) : row[item.prop])+" ")]):_c('span',{staticClass:"table-na-placeholder"},[_vm._v(" "+_vm._s(typeof item.formatter === 'function' ? item.formatter(row) : _vm.showDash ? '⏤' : '')+" ")])]}}],null,true)},'el-table-column',
        item.filters
          ? {
              filters: item.filters,
              filterMethod: _vm.filterHandler,
              filterPlacement: 'bottom'
            }
          : {}
      ,false))]}),_vm._t("operation")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }