import service from '@/service'
/**
 * 批量下载图片
 */
export function submit(data) {
  return service['api-web']({
    url: '/download/task/submit',
    method: 'post',
    data
  })
}

/**
 *获取图片下载信息（待办）
 */
export function downloadPicInfo(data) {
  return service['api-web']({
    url: '/download/task/query/downloadPicInfo',
    method: 'post',
    data
  })
}

/**
 *获取图片下载信息（待办）
 */
export function checkProduct(data) {
  return service['api-web']({
    url: '/download/task/checkProduct',
    method: 'post',
    data
  })
}

/**
 *
 *获取图片下载中心列表
 */
export function getList(data) {
  return service['api-web']({
    url: '/download/task/page',
    method: 'post',
    data
  })
}

export function auditReject(data) {
  return service['api-web']({
    url: '/download/task/auditReject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function auditFinish(data) {
  return service['api-web']({
    url: '/download/task/auditFinish',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
