<template>
  <div
    v-if="uuTaleKey"
    :key="uuTaleKey"
    :class="['base-inline-edit-table', ...externalClasses]"
  >
    <template v-if="allowRead">
      <vxe-toolbar
        v-show="hasToolBar"
        :key="tableToolbarKey"
        :ref="toolbarRefName"
        class="table-operation"
      >
        <template v-if="!disableOperate" #buttons>
          <!-- 插槽工具栏（覆盖内部按钮） -->
          <slot
            name="tool-buttons-slots"
            v-bind="{
              ...baseSlotScope,
              actived: currentEditScope,
              ref: tableRefName
            }"
          />
          <!-- 工具栏现有的按钮 -->
          <template v-if="!hasButtonsSlots">
            <!-- 新增 -->
            <el-button
              v-if="allowCreate"
              type="primary"
              @click="onClick('insert', null)"
            >
              <svg-icon
                v-if="operationBtnIcons.create"
                :icon-class="operationBtnIcons.create"
              />
              {{ customCreateText || btnCreateText }}
            </el-button>
            <!-- 批量删除 -->
            <el-button
              v-if="allowMultiDel"
              class="common-btn"
              @click="onClick('multi-del', null)"
            >
              <svg-icon
                v-if="operationBtnIcons?.multiDel"
                :icon-class="operationBtnIcons.multiDel"
              />
              {{ customDelText || btnMultiDelText }}
            </el-button>
          </template>
          <!-- 工具栏插槽（追加按钮） -->
          <slot
            name="external-buttons-slots"
            v-bind="{
              ...baseSlotScope,
              actived: currentEditScope,
              ref: tableRefName
            }"
          />
        </template>
        <!-- 工具栏插槽（非编辑模式也能显示,一般与表格操作并无关系） -->
        <template v-else #buttons>
          <slot
            name="external-buttons-slots-view-mode"
            v-bind="{
              ...baseSlotScope,
              actived: currentEditScope,
              ref: tableRefName
            }"
          />
        </template>
      </vxe-toolbar>

      <vxe-table
        :ref="tableRefName"
        :key="vxeTableKey"
        v-vxtableDrag="tableKey + dragColumnKey"
        :data="tableData"
        :border="border"
        :height="tableHeight"
        v-bind="$attrs"
        :min-height="minHeight"
        :max-height="maxHeightWrapper"
        :row-id="rowId"
        :row-key="rowKey"
        :highlight-current-row="highlightCurrentRowProxy"
        :highlight-hover-row="highlightHoverRowProxy"
        :row-config="rowConf"
        :column-config="columnConf"
        :mouse-config="mouseConf"
        :edit-config="editConf"
        :checkbox-config="checkboxConf"
        :tree-config="treeConf"
        :tooltip-config="tooltipConfig"
        :row-style="rowStyle"
        :edit-rules="validRules"
        :loading="tableLoading"
        :size="getSize"
        keep-source
        align="left"
        resizable
        :resize-config="resizeConf"
        :sync-resize="syncResize"
        :auto-resize="autoResize"
        :scroll-y="{ enabled: true, gt: 20 }"
        :show-overflow="getTableShowOverflow"
        :class="['base-table', 'base-vxe-table']"
        v-on="restListeners"
        @edit-disabled="onEditDisabled"
        @edit-closed="onEditClosed"
        @edit-actived="onEditOpened"
        @valid-error="onValidError"
        @toggle-tree-expand="onToggleTreeExpand"
        @cell-mouseleave="onCellLeave"
        @cell-mouseenter="onCellEnter"
        @cell-click="onCellClick"
        @cell-selected="onCellSelected"
        @resizable-change="onHeaderDragend"
        @checkbox-change="selectChangeEvent"
        @checkbox-all="selectAllEvent"
        @scroll="scrollTable"
        @sort-change="onSortChange"
      >
        <vxe-column
          v-for="(item, idx) in tableColumns"
          :key="idx"
          :align="item.align || 'center'"
          :cell-render="itemCellRender(item)"
          :content-render="itemContentRender(item)"
          :edit-render="itemEditRender(item, idx)"
          :class-name="item?.className"
          :field="item.prop || item.type"
          :fixed="
            item.regular === 'none' // 当不需要使用固定列时
              ? ''
              : tableColumns.find((v) => v.type === 'expand')
              ? ''
              : item.prop === 'operation'
              ? 'right'
              : item.type
              ? 'left'
              : ['left', 'right'].includes(item?.fixed)
              ? item.fixed
              : ''
          "
          :formatter="itemFormatter(item)"
          :params="collectItemRules(item)"
          :title="item.label"
          :type="item.type"
          :width="
            item.type ? (item.width ? item.width : '60') : item.width || 'auto'
          "
          :show-overflow="getItemShowOverflow(item)"
          :sortable="item.sortable"
          v-bind="itemColumn(item)"
          :min-width="item?.editRender?.rule ? 60 : 45"
        >
          <!-- 自定义表头 -->
          <template v-if="item.isCustomHeader" #header="columnScope">
            <slot
              :name="`${item.prop}Header`"
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName
                })
              "
            />
          </template>

          <!-- 展开内容 -->
          <template v-if="isExpandItem(item)" #content="columnScope">
            <slot
              name="content"
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName,
                  [item.prop]: columnScope.row[item.prop]
                })
              "
            />
          </template>

          <!-- 表格列：自定义组件 default render -->
          <template v-if="isSlotItem(item)" #default="columnScope">
            <svg-icon
              v-if="
                item.autoCopy &&
                hoveredRow === columnScope.row &&
                columnScope.row[item.prop]
              "
              icon-class="i-copy-hollow"
              style="cursor: pointer"
              class="copySvg"
              @click.stop="goHandleCopy(columnScope.row[item.prop])"
            />
            <slot
              :name="`${item.prop}Default`"
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName,
                  [item.prop]: columnScope.row[item.prop]
                })
              "
            />
          </template>
          <template
            v-else-if="item.prop === 'operation'"
            #default="columnScope"
          >
            <slot
              name="operationDefault"
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName
                })
              "
            />
          </template>

          <!-- 表格列：自定义组件 edit render -->
          <template v-if="isSlotItem(item)" #edit="columnScope">
            <slot
              :name="`${item.prop}Edit`"
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName,
                  [item.prop]: columnScope.row[item.prop],
                  ...item
                })
              "
            />
          </template>
          <template v-else-if="item.prop === 'operation'" #edit="columnScope">
            <slot
              v-bind="
                Object.assign(columnScope, {
                  ...baseSlotScope,
                  ref: tableRefName
                })
              "
              name="operationEdit"
            />
          </template>
          <!-- 表格列：筛选过滤 -->
          <template v-if="item.filterSlot" #filter="filterScope">
            <slot
              :name="`${item.prop}Filter`"
              v-bind="
                Object.assign(filterScope, {
                  ...baseSlotScope,
                  ref: tableRefName
                })
              "
            />
          </template>
        </vxe-column>

        <!-- 表格列：操作 -->
        <vxe-column
          v-if="
            (allowCreate ||
              allowUpdate ||
              allowDelete ||
              hasExtOperationSlots) &&
            showInnerOperationColumn
          "
          :fixed="tableColumns.find((v) => v.type === 'expand') ? '' : 'right'"
          v-bind="operationProps"
        >
          <template #default="columnScope">
            <template v-if="isEditing(columnScope.row) && isShowDefaultBtn">
              <template v-if="allowCreate || allowUpdate">
                <el-button
                  :size="size"
                  type="text"
                  :loading="btnSaveLoading"
                  @click="onClick('save', columnScope)"
                >
                  保存
                </el-button>
                <el-button
                  type="text"
                  :size="size"
                  @click="onClick('cancel', columnScope)"
                >
                  取消
                </el-button>
              </template>
            </template>
            <template v-else>
              <!-- 操作列追加插槽 (first) -->
              <slot
                name="external-operation-slots-start"
                v-bind="Object.assign(columnScope, { ...baseSlotScope })"
              />
              <!-- 添加子集 -->
              <el-button
                v-if="allowCreate && allowAppendChild(columnScope)"
                :size="size"
                type="text"
                @click="onClick('append', columnScope)"
              >
                <svg-icon
                  v-if="operationBtnIcons?.append"
                  :icon-class="operationBtnIcons.append"
                />
                添加
              </el-button>
              <!-- 编辑 -->
              <el-button
                v-if="allowUpdate && !hideUpdateRow(columnScope)"
                :disabled="disableUpdateRow(columnScope)"
                :size="size"
                type="text"
                @click="onClick('edit', columnScope)"
              >
                <svg-icon
                  v-if="operationBtnIcons?.edit"
                  :icon-class="operationBtnIcons.edit"
                />
                {{ btnUpdateText }}
              </el-button>
              <!-- 删除 -->
              <el-button
                v-if="allowDelete && !hideRemoveRow(columnScope)"
                :disabled="disableRemoveRow(columnScope)"
                :size="size"
                type="text"
                @click="onClick('delete', columnScope)"
              >
                <svg-icon
                  v-if="operationBtnIcons?.delete"
                  class="fontSize_16"
                  :icon-class="operationBtnIcons.delete"
                />
                {{ btnDeleteText }}
              </el-button>
              <!-- 操作列追加插槽 (end) -->
              <slot
                name="external-operation-slots-end"
                v-bind="Object.assign(columnScope, { ...baseSlotScope })"
              />
            </template>
          </template>
        </vxe-column>

        <!-- 自定义操作列插槽 -->
        <slot
          name="column-operation-slots"
          v-bind="{ ...baseSlotScope, actived: currentEditScope }"
        />

        <template #empty>
          <p>
            <i class="vxe-icon--warning" />
            {{ emptyText }}
          </p>
        </template>
      </vxe-table>
    </template>
    <vxe-table v-else :data="[]">
      <vxe-column type="seq" width="60" />
      <template #empty>
        <p>
          <i class="vxe-icon--eye-slash" />
          {{ noPmText }}
        </p>
      </template>
    </vxe-table>
  </div>
</template>
<script>
/**
 * ******************************************************************************************
 * @file 基于 vxe-table 二次封装
 * @see https://xuliangzhan_admin.gitee.io/vxe-table/
 *
 * ·
 * ├── index.vue
 * ├── consts.js                    常量
 * └── mixins                       宏分割目录
 *     ├── init.js                  宏/初始化
 *     ├── crud.js                  宏/远程增删改查（包含鉴权 以及 工具栏/操作列:显示隐藏，按钮文案...）
 *     ├── refresh.js               宏/请求远程刷新
 *     ├── renders.js               宏/表格单元格渲染
 *     ├── row-external.js          宏/行逻辑数据特殊命名空间管理
 *     ├── tree.js                  宏/树结构
 *     ├── rules.js                 宏/校验规则
 *     └── valid.js                 宏/校验
 *
 * 备注：
 * 1.  特殊变量和特定的数据命名空间
 *    -  _XID                    官方   table row 内部 id (版本差异)
 *    -  _X_ID                   官方   table row 内部 id (版本差异)
 *    - __BIET_ROW_EXTERNAL__    非官方 table row 额外扩展数据，用于开展内部逻辑
 *    - __BIET_RENDER_INJECT__   非官方 table column item 的 render 中声明(如 editRender), 用于表格内部处理逻辑
 *    - ...
 *
 * 2. ...
 *
 * *******************************************************************************************
 */

/**
 * @typedef {import('vxe-table').RowInfo} TableRow
 * @typedef {import('vxe-table').ColumnInfo} TableColumn
 */

/**
 * ⚠️ vxe table 文档 关于 tree transform 自动转换的相关字段仅支持 v4+,
 * 实测 v3 可以运行，但是某些边界情况还是存在一些问题
 * 因此借用 vxe table 的官方工具 xe-utils 自行实现 data 的 transform
 * @see vxeUnFlatten
 * @see vxeFlatten
 */
import { debounce } from '@/utils'

import {
  toTreeArray as vxeFlatten,
  toArrayTree as vxeUnFlatten
} from 'xe-utils'
import poll from '@/utils/poll'
import { get as getCookie } from '@/utils/cookies'
import { isArray, isString, isPlainObject } from '@/utils/validate'
import { deepClone } from '@/utils/object'

import { getSyncAsyncReturns } from '@/utils'
import { arrayEqual, includeAll, includeSome } from '@/utils/array'
import { createUniqueString, uppercaseFirst } from '@/utils/string'
import listHeaderCache from '@/storage/local/listHeader'
import {
  DEFAULT_FLAGS,
  CRUD,
  DEFAULT_CRUD_HANDLER_CONF,
  BORDER_TYPES
} from './consts'

import init from './mixins/init'
import renders from './mixins/renders'
import rules from './mixins/rules'
import valid from './mixins/valid'
// import tree from './mixins/tree'
// import crud from './mixins/crud'
import refresh from './mixins/refresh'
import rowExternal from './mixins/row-external'

import elFileUploaderMixin from '@/plugins/vxe-table/custom-renderers/ElFileUploader/mixin'
import { POLL_TIMEOUT } from '@/constants'
import vxtableDrag from '@/directive/vxtableDrag'
import { handleCopy } from '@/utils/clipboard'

export default {
  name: 'BaseInlineEditTable',
  directives: { vxtableDrag },
  mixins: [
    init,
    renders,
    rules,
    valid,
    // tree,
    // crud,
    refresh,
    rowExternal,
    elFileUploaderMixin
  ],
  props: {
    /** 拖动改变table 高度 */
    maxHeight: {
      type: [String, Number],
      default: undefined
    },
    /** 拖动改变table 高度 */
    minHeight: {
      type: [String, Number],
      default: undefined
    },
    //  拖拽列的唯一key值
    dragColumnKey: {
      type: [String, Number],
      default: undefined
    },

    /**
     * @description page scope
     */
    scope: {
      type: Object,
      default: () => ({})
    },

    tableKey: {
      type: String,
      required: true
    },
    validRules: {
      type: Object,
      default: () => ({})
    },

    /** 自定义新增按钮文本 */
    customCreateText: {
      type: String,
      required: false,
      default: ''
    },

    /** 自定义删除按钮文本 */
    customDelText: {
      type: String,
      required: false,
      default: ''
    },
    /** 自定义删除结果弹窗文本 */
    deleteText: {
      type: String,
      required: false,
      default: ''
    },
    /** 自定义删除结果类型 */
    deleteType: {
      type: String,
      default: 'success'
    },

    tableLoading: {
      type: Boolean,
      default: false
    },
    /** 开启临时数据添加 */
    temporaryDataStorage: {
      type: Boolean,
      default: false
    },
    /** 开启临时数据删除 */
    removeTemporaryDataStorage: {
      type: Boolean,
      default: false
    },
    /** 表格内部保存单行之后,停止校验全局 */
    saveSingleRowNotGlobal: {
      type: Boolean,
      default: false
    },

    renderRowColor: {
      type: Boolean,
      default: false
    },

    // 是否是当前行中的操作列
    isRowAction: {
      type: Boolean,
      default: true
    },

    tableHeader: {
      type: Array,
      required: true,
      default: () => []
    },

    data: {
      type: [Array, null],
      default: null
    },

    value: {
      type: [Array, null],
      default: null
    },

    emptyText: {
      type: String,
      default: '暂无数据'
    },

    noPmText: {
      type: String,
      default: '暂无权限访问，请联系管理员'
    },

    cellPlaceholder: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: ''
    },

    height: {
      type: [String, Number],
      default: undefined
    },

    border: {
      type: [Boolean, String],
      default: 'full',
      validator: (border) =>
        BORDER_TYPES.includes(border) || typeof border === 'boolean'
    },

    externalClass: {
      type: [Array, String],
      default: ''
    },

    showIndex: {
      type: Boolean,
      default: true
    },

    showOperationColumn: {
      type: Boolean,
      default: true
    },

    syncResize: {
      type: [String, Boolean, Number],
      default: true
    },

    autoResize: {
      type: Boolean,
      default: true
    },

    /**
     * 行配置
     */
    rowConfig: {
      type: [Object, undefined],
      default: undefined
    },
    /**
     * @deprecated 被 rowConfig.keyField 替换
     */
    rowId: {
      type: [String, undefined],
      default: undefined
    },

    /**
     * @deprecated 被 rowConfig.useKey 替换
     */
    rowKey: {
      type: Boolean,
      default: true
    },
    /**
     * @deprecated 被 rowConfig.isCurrent 替换
     */
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    /**
     * @deprecated 被 rowConfig.isHover 替换
     */
    highlightHoverRow: {
      type: Boolean,
      default: false
    },

    /**
     * @deprecated 被 columnConfig.isCurrent 替换
     */
    highlightCurrentColumn: {
      type: Boolean,
      default: false
    },
    /**
     * @deprecated 被 columnConfig.isHover 替换
     */
    highlightHoverColumn: {
      type: Boolean,
      default: true
    },

    /**
     * 复选框配置
     */
    checkboxConfig: {
      type: [Object, undefined],
      default: undefined
    },

    /**
     * 编辑配置
     */
    editConfig: {
      type: [Object, undefined],
      default: undefined
    },

    columnConfig: {
      type: [Object, undefined],
      default: undefined
    },

    mouseConfig: {
      type: [Object, undefined],
      default: undefined
    },

    resizeConfig: {
      type: [Object, undefined],
      default: undefined
    },

    /**
     * 提示配置
     */
    tooltipConfig: {
      type: Object,
      default: () => ({})
    },
    // 表格是否初始化编辑状态
    isTableInitEdit: {
      type: Boolean,
      default: false
    },

    /**
     * @see https://vxetable.cn/v3/#/table/api
     * @see https://vxetable.cn/v3/#/column/api
     * search 'show-overflow'
     */
    showOverflow: {
      type: [Boolean, String],
      default: false,
      validator: (value) => {
        return (
          typeof value === 'boolean' ||
          !!(
            isString(value) && ['ellipsis', 'title', 'tooltip'].includes(value)
          )
        )
      }
    },
    /**
     * crud for operation locally
     * @see CRUD
     */
    crudLocal: {
      type: Array,
      default: () => [],
      validator: (crudLocal) => {
        return (
          !crudLocal.length || (crudLocal.length && includeAll(CRUD, crudLocal))
        )
      }
    },
    /**
     * external crud handler
     */
    crudHandler: {
      type: Object,
      default: () => ({
        config: {},
        read: null, // must return list array and Promise<list array>
        create: null, // must return boolean and Promise<boolean>
        update: null, // must return boolean and Promise<boolean>
        delete: null, // must return boolean and Promise<boolean>
        multiDel: null // must return boolean and Promise<boolean>
      }),
      validator: (crudHandler) => {
        const handler = Object.keys(crudHandler)
        const legalHandler = handler
          .map((key) => (key !== 'config' ? crudHandler[key] : null))
          .filter((v) => !!v)
          .every((handler) => typeof handler === 'function' || handler === null)
        return CRUD.length === handler.length
          ? arrayEqual(CRUD, handler) && legalHandler
          : legalHandler
      }
    },

    /**
     * crud permission fullnames
     * if crudHandler item need permission control
     */
    crudPmFullname: {
      type: Object,
      default: () => ({
        create: '',
        read: '',
        update: '',
        delete: '',
        multiDel: ''
      }),
      validator: (crudPmFullname) => {
        const legalPms = Object.values(crudPmFullname).every(
          (pm) => isString(pm) || (isArray(pm) && pm.every((p) => isString(p)))
        )
        return legalPms
      }
    },

    /**
     * crud fixed btn labels
     * if curd buttons need fixed text
     */
    crudBtnLabel: {
      type: Object,
      default: () => ({
        create: '',
        read: '',
        update: '',
        delete: '',
        multiDel: ''
      }),
      validator: (crudBtnLabel) => {
        const legalLabels = Object.values(crudBtnLabel).every(
          (label) => typeof label === 'undefined' || isString(label)
        )
        return legalLabels
      }
    },
    /**
     * btn icons
     */
    btnIcons: {
      type: Object,
      default: () => ({
        create: 'i-add',
        update: undefined,
        delete: undefined,
        multiDel: 'i-delete'
      })
    },
    /**
     * 树表格配置, vxe table api
     */
    treeConfig: {
      type: [Object, undefined],
      default: undefined
    },

    treeNodeOnIndex: {
      type: Boolean,
      default: true
    },
    /**
     * 树表格配置, 定制化扩展
     */
    treeSpecial: {
      /**
       * @typedef {Object} TreeSpecial
       * @property {('all'|'never'|'child'|'parent'|'hasChildren')} [disableEditTreeRow='never'] 禁止树节点编辑
       * @property {Function} isParent 判断是否是父级, 输入 { row } 输出 boolean
       * @property {Function} allowToAppend 允许添加子集的判断， return boolean
       * @property {Function} beforeValid 校验之前的钩子
       * @property {Function} ignoreValidErr 是否忽略校验错误
       */
      /**
       * @type {TreeSpecial}
       * @description 扩展树表格配置
       */
      type: [Object, undefined],
      default: undefined
    },

    /**
     * 表格是否禁止操作
     */
    disableOperate: {
      type: Boolean,
      default: false
    },
    /**
     * 表格是否有工具栏
     */
    hasToolBar: {
      type: Boolean,
      default: true
    },

    /**
     * 钩子函数 before save row
     */
    beforeSaveRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 after save row
     */
    afterSaveRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 can insert row
     */
    canInsertRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 can edit row
     */
    canEditRow: {
      type: Function,
      default: () => true
    },
    /**
     * 钩子函数 can delete row
     * */
    canDeleteRow: {
      type: Function,
      default: () => true
    },
    /**
     * 钩子函数 before insert row
     */
    beforeInsertRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 after insert row
     */
    afterInsertRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 before append row
     */
    beforeAppendRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 before remove row
     */
    beforeRemoveRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 钩子函数 after append row
     */
    afterAppendRow: {
      type: [Function, null, undefined],
      default: undefined
    },
    /**
     * 操作列的配置信息
     */
    operationProps: {
      type: Object,
      default: () => ({ width: '160', title: '操作' })
    },
    /**
     * 删除后内部是否执行一次刷新
     */
    afterRemoveRefresh: {
      type: Boolean,
      default: true
    },

    showRemoveRowConfirmBox: {
      type: Boolean,
      default: true
    },

    ignoreEdit: {
      type: Boolean,
      default: false
    },

    checkBeforeEdit: {
      type: Boolean,
      default: true
    },

    isShowErrorMessageInFileUploader: {
      type: Boolean,
      default: true
    },

    remoteHeader: {
      type: Array,
      default: () => []
    },
    remoteHeaderTitle: {
      type: String,
      default: null
    },
    /**
     * 当前行正在编辑的时候 禁止其他行编辑
     */
    rowEditingDisabledOtherRow: {
      type: Boolean,
      default: false
    },
    isShowDefaultBtn: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      slots: {},
      /**
       * @type {object}
       * @description 表格标识
       * @see DEFAULT_FLAGS
       */
      flags: {
        ...DEFAULT_FLAGS
      },
      /**
       * @type {object}
       * @description 当前表格 refs
       */
      $table: null,
      /**
       * @type {object}
       * @description 当前表格上方按钮工具栏 refs
       */
      $toolbar: null,
      /**
       * @type {object}
       * @description 表格当前编辑行作用域
       */
      currentEditScope: null,
      /**
       * @type {array}
       * @description 表格多选框勾选值
       */
      checkboxData: [],
      /**
       * @type {boolean}
       * @description 表格内部操作列保存按钮 loading
       */
      btnSaveLoading: false,
      /**
       * @type {string}
       * @description 鼠标经过当前row，用于单元格自动复制
       */
      hoveredRow: null,
      /**
       * @type {string}
       * @description 是否有正在编辑的行
       */
      rowEditing: false
    }
  },

  computed: {
    getSize() {
      return (
        this.size || this.$store.getters.size || getCookie('size') || 'mini'
      )
    },
    borderSync() {
      if (['inner', 'default', 'none', false].includes(this.border)) {
        return 'none'
      } else return 'block'
    },
    /**
     * @returns {(boolean|'ellipsis'|'title'|'tooltip')}
     * @description 兼容误使用 element-ui 中的 show-overflow-tooltip 的情况
     * 开发的时候有些表格实例配置错了，还是使用 element-ui 的 show-overflow-tooltip 来配置 vxe-table 的 show-overflow
     */
    getTableShowOverflow() {
      if (Object.keys(this.$attrs).includes('show-overflow-tooltip')) {
        const showOverflowTooltip = this.$attrs['show-overflow-tooltip']
        if (
          typeof showOverflowTooltip === 'boolean' ||
          !!(
            isString(showOverflowTooltip) &&
            ['ellipsis', 'title', 'tooltip'].includes(showOverflowTooltip)
          )
        ) {
          console.log(showOverflowTooltip, 'showOverflowTooltips')
          return showOverflowTooltip
        } else {
          console.error(
            `${this.$router?.currentRoute?.name}: Invalid prop: custom validator check failed for prop "showOverflowTooltip".`
          )
        }
      }
      /**
       * 有固定列时，全局的show-overflow配置会导致固定列和其他列错位的情况
       * 因此，禁止通过全局设置 show-overflow，需要通过每个列的 item 进行配置
       */
      return this.hasFixedColumns === true ? false : this.showOverflow
    },
    /**
     * @param {*} scope - table row,columns,...
     * @returns {boolean} 是否禁用编辑按钮 (闭包，支持传参)
     */
    disableUpdateRow: {
      cache: false,
      get() {
        return function (scope) {
          return this.getRowExternal({
            row: scope?.row,
            key: 'disabledUpdate'
          })
        }
      }
    },
    /**
     * @param {*} scope - table row,columns,...
     * @returns {boolean} 是否禁用删除按钮 (闭包，支持传参)
     */
    disableRemoveRow: {
      cache: false,
      get() {
        return function (scope) {
          return this.getRowExternal({
            row: scope?.row,
            key: 'disabledRemove'
          })
        }
      }
    },
    disabledRowUpdateField() {
      return this.rowConfig?.disabledField?.update || 'disabledUpdate'
    },
    disabledRowRemoveField() {
      return this.rowConfig?.disabledField?.remove || 'disabledRemove'
    },
    highlightCurrentRowProxy() {
      return typeof this.rowConfig?.isCurrent === 'boolean'
        ? this.rowConfig.isCurrent
        : typeof this.highlightCurrentRow === 'boolean'
        ? this.highlightCurrentRow
        : false
    },
    highlightHoverRowProxy() {
      return typeof this.rowConfig?.isHover === 'boolean'
        ? this.rowConfig.isHover
        : typeof this.highlightHoverRow === 'boolean'
        ? this.highlightHoverRow
        : false
    },
    uuTaleKey() {
      return this.tablekey
        ? `${createUniqueString()}`
        : `${this.tableKey}-${createUniqueString()}`
    },
    tableToolbarKey() {
      return `toolbar-of-${this.uuTaleKey}`
    },
    toolbarRefName() {
      return `ref-to-${this.tableToolbarKey}`
    },
    vxeTableKey() {
      return `table-of-${this.uuTaleKey}`
    },
    tableRefName() {
      return `ref-to-${this.vxeTableKey}`
    },
    /**
     * @returns {(string|number)=}
     */
    tableHeight() {
      return this.height || this.scope?.tableHeight || undefined
    },

    restListeners() {
      const existing = [
        'edit-disabled',
        'edit-closed',
        'edit-actived',
        'valid-error',
        'toggle-tree-expand',
        'cell-mouseleave',
        'cell-mouseenter',
        'checkbox-change',
        'checkbox-all',
        'cell-click'
      ]
      const listeners = {}
      Object.entries(this.$listeners).forEach(([event, handler]) => {
        if (!existing.includes(event)) listeners[event] = handler
      })
      return listeners
    },
    /**
     * 外部传入自定义 class
     */
    externalClasses() {
      return isString(this.externalClass) && this.externalClass
        ? [this.externalClass]
        : isArray(this.externalClass)
        ? this.externalClass.filter(
            (className) => isString(className) && className
          )
        : []
    },
    /**
     * 表头/列
     * @description 第一列：showIndex === true 且如果没有 type 列 后者没有声明序号列，则默认插入序号列
     */
    columns() {
      try {
        let tableHeader = [...this.tableHeader]
        tableHeader = tableHeader.filter((item) => !item.hidden)

        if (this.showIndex !== false) {
          const typeColumn = tableHeader.filter(
            (v) => isString(v.type) && v.type
          )
          if (
            !typeColumn.length ||
            !typeColumn.find((v) => v.type === 'seq' || this.isTree)
          ) {
            tableHeader.unshift({
              type: 'seq',
              width: '60'
            })
          }
          /* 判断当前表格是不是树渲染表格 */
          if (this.isTree && this.treeNodeOnIndex === true) {
            tableHeader.unshift({
              prop: 'index',
              label: '序号',
              width: '80',
              treeNode: true
            })
          }
        } else {
          const indexColumnIdx = tableHeader.findIndex((v) => v.type === 'seq')
          if (indexColumnIdx >= 0) {
            tableHeader.splice(indexColumnIdx, 1)
          }
        }

        return tableHeader.map((v) => {
          if (v.type === 'seq') {
            return {
              type: 'seq',
              label: isString(this.seqLabel) ? this.seqLabel : '序号',
              ...v
            }
          } else return v
        })
      } catch (error) {
        console.error('【BaseInlineEditTable】: columns calc error ', error)
        return this.tableHeader
      }
    },

    tableColumns() {
      const { key, setup } = listHeaderCache

      let result = setup(key.call(this, this.tableKey), this.columns)
      console.log('👀LOG -> result1:', result)
      console.log('👀LOG -> result2:', this.remoteHeader)
      if (this.remoteHeader?.length > 0) {
        result = result.filter((local) => {
          if (local.type && !local.label) return true

          return this.remoteHeader.find(
            (remote) => local.label + local.prop === remote.label + remote.prop
          )?.isShow
        })
      }
      if (
        (this.remoteHeaderTitle === 'ORDER_SEPARATE' ||
          this.remoteHeaderTitle === 'ORDER_CONFIRM') &&
        !result.some((item) => item.type === 'seq')
      ) {
        result.unshift({
          type: 'seq',
          label: '序号'
        })
      }

      console.log('👀LOG -> result:', result)

      return result
    },

    tableEditing() {
      return this.flags.tableEditing
    },
    /**
     * 表格数据
     */
    tableData() {
      const value =
        isArray(this.data) && this.data.length ? this.data : this.value

      if (value === null || !isArray(value)) {
        return []
      }

      if (!value.length) {
        return value
      }

      const data = [...value]
      const _data = this.isTree
        ? vxeUnFlatten(
            this.initRowExternal(
              this.treeDataNeedTransform
                ? data
                : vxeFlatten(data, {
                    children: this.treeChildrenField
                  })
            ),
            {
              key: this.treeRowField,
              parentKey: this.treeParentField
            }
          )
        : this.initRowExternal(data)
      return _data
    },
    /**
     * @returns {boolean} 当前表格是否是“单元格编辑”模式
     */
    isCellMode() {
      return this.editConf.mode === 'cell'
    },

    /**
     * @return {boolean} 表格上方按钮是否有外部插槽插入
     */
    hasButtonsSlots() {
      return this.slots['tool-buttons-slots'] === true
    },

    /**
     * @return {boolean}
     */
    hasExtOperationSlots() {
      return (
        this.slots['external-operation-slots-start'] === true ||
        this.slots['external-operation-slots-end'] === true
      )
    },

    /**
     * @return {boolean}  表格操作列是否由外部插槽插入
     */
    hasOperationSlots() {
      return this.tableHeader.filter((v) => v.prop === 'operation').length
    },

    /**
     * @returns {boolean} 表格是否显示内部操作列
     */
    showInnerOperationColumn() {
      return (
        !this.disableOperate &&
        !this.isCellMode &&
        !this.hasOperationSlots &&
        this.showOperationColumn
      )
    },

    /**
     * @returns {((object|void)|undefined)} 树表格配置项目
     * @description 去除 vxe-table@v3 版本不兼容的 transform, rowField, parentField
     */
    treeConf() {
      if (!this.isTree) return undefined

      const {
        transform, // eslint-disable-line no-unused-vars
        rowField, // eslint-disable-line no-unused-vars
        parentField, // eslint-disable-line no-unused-vars
        lazy,
        loadMethod,
        menuTree, // 菜单管理特殊添加
        ...rest
      } = this.treeConfig

      let _config = {
        ...rest,
        // toggleMethod: this.interceptToggleTreeExpand,
        reserve: !this.treeIsLazy
      }

      if (lazy && loadMethod && rowField && !menuTree) {
        _config = this.treeConfig
      }

      if (typeof loadMethod === 'function') {
        _config.loadMethod = async (e) => {
          const children = await loadMethod(e)
          children.forEach((row) => {
            this.setRowExternal({
              row,
              key: this.treeParentField,
              value: e?.row?.[this.treeRowField] ?? null
            })
          })
          return children
        }
      }

      return _config
    },

    /**
     * @returns {boolean} 当前表格是否是树结构渲染表格
     */
    isTree() {
      return (
        isPlainObject(this.treeConfig) && !!Object.keys(this.treeConfig).length
      )
    },

    /**
     * @returns {boolean} 当前树表格配置是否使用了 非官方的 API
     */
    hasTreeSpecial() {
      return (
        isPlainObject(this.treeSpecial) &&
        !!Object.keys(this.treeSpecial).length
      )
    },
    /**
     * @returns {boolean} 树表格是否需要数据转换
     */
    treeDataNeedTransform() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { transform = false, ...rest } = this.treeConfig
        return typeof transform === 'boolean' ? transform : false
      } else return false
    },

    /**
     * @returns {boolean} 树表格是否是懒加载
     */
    treeIsLazy() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { lazy = false, loadMethod = null, ...rest } = this.treeConfig
        return typeof lazy === 'boolean' && typeof loadMethod === 'function'
          ? lazy
          : false
      } else return false
    },
    /**
     * @returns {string} 树字段：父ID
     */
    treeParentField() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { parentField = 'parentId', ...rest } = this.treeConfig
        return isString(parentField) && parentField ? parentField : 'parentId'
      } else return 'parentId'
    },
    /**
     * @returns {string} 树字段：ID
     */
    treeRowField() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { rowField = 'id', ...rest } = this.treeConfig
        return isString(rowField) && rowField ? rowField : 'id'
      } else return 'id'
    },
    /**
     * @returns {string} 树字段：children array
     */
    treeChildrenField() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { children = 'children', ...rest } = this.treeConfig
        return isString(children) && children ? children : 'children'
      } else return 'children'
    },
    /**
     * @returns {string} 树字段：hasChild for lazy
     */
    treeHasChildField() {
      if (this.isTree) {
        // eslint-disable-next-line no-unused-vars
        const { hasChild = 'hasChild', ...rest } = this.treeConfig
        return isString(hasChild) && hasChild ? hasChild : 'hasChild'
      } else return 'hasChild'
    },
    /**
     * @typedef {('all'|'never'|'parent'|'child'|'hasChildren')} DisableParentRowEdit
     * - all:          禁止所有树节点编辑
     * - never:        不禁止树节点编辑
     * - parent:       禁止父节点编辑
     * - child:        禁止子节点编辑
     * - hasChildren:  禁止有子节点或子节点已经展开的节点编辑
     */
    /**
     * @returns {DisableParentRowEdit} 节点禁止编辑的条件 'all','never','parent','child','hasChildren'
     */
    treeDisableEditRow() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { disableEditTreeRow = 'never', ...rest } = this.treeSpecial
        return ['all', 'never', 'parent', 'child', 'hasChildren'].includes(
          disableEditTreeRow
        )
          ? disableEditTreeRow
          : 'never'
      } else return 'never'
    },
    /**
     * @returns {(Function|null)} 是否是树父节点额外的判断逻辑，来源外部配置
     */
    treeIsParentExternalCondition() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { isParent = null, ...rest } = this.treeSpecial
        return typeof isParent === 'function' ? isParent : null
      } else return null
    },
    /**
     * @returns {(Function|null)} 当前行是否允许添加子集，来源外部配置
     */
    treeAllowToAppend() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { allowToAppend = null, ...rest } = this.treeSpecial
        return typeof allowToAppend === 'function' ? allowToAppend : null
      } else return null
    },
    /**
     * @returns {(Function|null)} 当前行是否允许添加子集，来源外部配置
     */
    treeAllowToRemove() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { allowToRemove = null, ...rest } = this.treeSpecial
        return typeof allowToRemove === 'function' ? allowToRemove : null
      } else return null
    },
    /**
     * @returns {(Function|null)}  hook fn that only for tree: before form valid
     */
    treeBeforeValid() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { beforeValid = null, ...rest } = this.treeSpecial
        return typeof beforeValid === 'function' ? beforeValid : null
      } else return null
    },
    /**
     * hook fn: after form valid
     */
    /**
     * @returns {(Function|null)}  hook fn that only for tree: after form valid
     */
    treeValidErrFilter() {
      if (this.hasTreeSpecial) {
        // eslint-disable-next-line no-unused-vars
        const { ignoreValidErr = null, ...rest } = this.treeSpecial
        return typeof ignoreValidErr === 'function' ? ignoreValidErr : null
      } else return null
    },

    /**
     * @returns {object} 表格行配置
     */
    rowConf() {
      // eslint-disable-next-line no-unused-vars
      const { disabledField, ...rest } = this.rowConfig || {}
      const origin = rest
      return {
        // 是否需要为每一行的 VNode 设置 key 属性（非特殊情况下不需要使用）
        useKey: typeof this.rowKey === 'boolean' ? this.rowKey : false,
        // 自定义行数据唯一主键的字段名（默认自动生成）
        keyField: this.rowId || '_X_ROW_KEY',
        // 当鼠标点击行时，是否要高亮当前行
        isCurrent:
          typeof this.highlightCurrentRow === 'boolean'
            ? this.highlightCurrentRow
            : false,
        // 当鼠标移到行时，是否要高亮当前行
        isHover:
          typeof this.highlightHoverRow === 'boolean'
            ? this.highlightHoverRow
            : false,
        // 只对 show-overflow 有效，每一行的高度
        height: undefined,
        ...origin
      }
    },

    /**
     * @returns {object} 表格列配置
     */
    columnConf() {
      const origin = this.columnConfig || {}
      return {
        // 是否需要为每一行的 VNode 设置 key 属性（非特殊情况下不需要使用）
        useKey: typeof this.columnKey === 'boolean' ? this.columnKey : false,
        // 当鼠标点击列时，是否要高亮当前列
        isCurrent:
          typeof this.highlightCurrentColumn === 'boolean'
            ? this.highlightCurrentRow
            : false,
        // 当鼠标点击列时，是否要高亮当前列
        isHover:
          typeof this.highlightHoverColumn === 'boolean'
            ? this.highlightHoverColumn
            : true,
        ...origin
      }
    },
    mouseConf() {
      const origin = this.mouseConfig || {}
      return {
        selected: true,
        ...origin
      }
    },

    resizeConf() {
      // const origin = this.resizeConfig || {}/
      return {
        refreshDelay: 320
      }
    },

    /**
     * @returns {object} 表格复选框配置
     */
    checkboxConf() {
      return { reserve: true, ...this.checkboxConfig }
    },

    /**
     * @returns {object} 表格编辑配置
     */
    editConf() {
      const origin = this.editConfig || {}

      const { activeMethod, ...rest } = origin

      const _activeMethod = ({ row, rowIndex, column, columnIndex }) => {
        const allowActive =
          typeof activeMethod === 'function'
            ? !!activeMethod({ row, rowIndex, column, columnIndex })
            : true

        return allowActive
      }

      return origin?.mode === 'cell'
        ? {
            trigger: 'manual',
            mode: 'row',
            autoClear: false, // 点击表格外区域自动失去焦点
            // showStatus: true,
            activeMethod: _activeMethod,
            ...rest
          }
        : {
            trigger: 'manual',
            mode: 'row',
            autoClear: false, // 点击表格外区域自动失去焦点
            // showStatus: true,
            _activeMethod,
            ...rest
          }
    },

    /**
     * @returns {boolean} 判断是否有查询权限
     */
    hasQueryPermission() {
      return this.crudPmFullname?.read
        ? this.$_permission(
            isString(this.crudPmFullname.read)
              ? [this.crudPmFullname.read]
              : isArray(this.crudPmFullname.read)
              ? this.crudPmFullname.read
              : `illegal query permission "${this.crudPmFullname.read}"`,
            { by: 'fullname' }
          )
        : false
    },
    /**
     * @returns {boolean} 判断是否有新增权限
     */
    hasCreatePermission() {
      return this.crudPmFullname?.create
        ? this.$_permission(
            isString(this.crudPmFullname.create)
              ? [this.crudPmFullname.create]
              : isArray(this.crudPmFullname.create)
              ? this.crudPmFullname.create
              : `illegal create permission "${this.crudPmFullname.create}"`,
            { by: 'fullname' }
          )
        : false
    },
    /**
     * @returns {boolean} 判断是否有修改权限
     */
    hasUpdatePermission() {
      return this.crudPmFullname?.update
        ? this.$_permission(
            isString(this.crudPmFullname.update)
              ? [this.crudPmFullname.update]
              : isArray(this.crudPmFullname.update)
              ? this.crudPmFullname.update
              : `illegal update permission "${this.crudPmFullname.update}"`,
            { by: 'fullname' }
          )
        : false
    },
    /**
     * @returns {boolean} 判断是否有删除权限
     */
    hasDeletePermission() {
      return this.crudPmFullname?.delete
        ? this.$_permission(
            isString(this.crudPmFullname.delete)
              ? [this.crudPmFullname.delete]
              : isArray(this.crudPmFullname.delete)
              ? this.crudPmFullname.delete
              : `illegal delete permission "${this.crudPmFullname.delete}"`,
            { by: 'fullname' }
          )
        : false
    },
    /**
     * @returns {boolean} 判断是否有批量删除权限
     */
    hasMultiDelPermission() {
      return this.crudPmFullname?.multiDel
        ? this.$_permission(
            isString(this.crudPmFullname.multiDel)
              ? [this.crudPmFullname.multiDel]
              : isArray(this.crudPmFullname.multiDel)
              ? this.crudPmFullname.multiDel
              : `illegal multi-del permission "${this.crudPmFullname.multiDel}"`,
            { by: 'fullname' }
          )
        : false
    },
    /**
     * @returns {boolean} 是否允许自由新增（本地操作表格，数据存储在 vxe-table 中）
     */
    freeCreate() {
      return !!(
        this.crudLocal.length > 0 &&
        includeSome(['create', 'c'], this.crudLocal)
      )
    },
    /**
     * @returns {boolean} 是否允许自由查询（本地操作表格，数据存储在 vxe-table 中）
     */
    freeRead() {
      return !!(
        this.crudLocal.length > 0 && includeSome(['read', 'r'], this.crudLocal)
      )
    },
    /**
     * @returns {boolean} 是否允许自由修改（本地操作表格，数据存储在 vxe-table 中）
     */
    freeUpdate() {
      return !!(
        this.crudLocal.length > 0 &&
        includeSome(['update', 'u'], this.crudLocal)
      )
    },
    /**
     * @returns {boolean} 是否允许自由删除（本地操作表格，数据存储在 vxe-table 中）
     */
    freeDelete() {
      return !!(
        this.crudLocal.length > 0 &&
        includeSome(['delete', 'd'], this.crudLocal)
      )
    },
    /**
     * @returns {boolean} 是否允许自由批量删除（本地操作表格，数据存储在 vxe-table 中）
     */
    freeMultiDel() {
      return !!(
        this.crudLocal.length > 0 &&
        includeSome(['multi-delete', 'multi-del', 'md'], this.crudLocal)
      )
    },
    /**
     * @returns {boolean} 表格是否允许访问
     */
    allowRead() {
      return this.freeRead || this.hasQueryPermission
    },
    /**
     * @returns {boolean} 表格是否允许新增
     */
    allowCreate() {
      return this.freeCreate || this.hasCreatePermission
    },
    /**
     * @returns {boolean} 表格是否允许删除行
     */
    allowDelete() {
      return this.freeDelete || this.hasDeletePermission
    },
    /**
     * @returns {boolean} 表格是否允许编辑保存
     */
    allowUpdate() {
      return this.freeUpdate || this.hasUpdatePermission
    },
    /**
     * @returns {boolean} 表格是否允许批量删除
     */
    allowMultiDel() {
      return this.freeMultiDel || this.hasMultiDelPermission
    },
    /**
     * @returns {string} 新增按钮的按钮文案
     */
    btnCreateText() {
      if (!this.hasCreatePermission && !this.freeCreate) return ''
      const fixedText =
        isString(this.crudBtnLabel.create) && this.crudBtnLabel.create.trim()
          ? this.crudBtnLabel.create
          : ''
      const text =
        fixedText ||
        (isString(this.crudPmFullname.create) &&
        this.crudPmFullname.create.trim()
          ? this.$_permission_name(this.crudPmFullname.create)
          : isArray(this.crudPmFullname.create) &&
            isString(this.crudPmFullname.create[0]) &&
            this.crudPmFullname.create[0].trim()
          ? this.$_permission_name(this.crudPmFullname.create[0])
          : this.freeCreate
          ? '新增'
          : '')

      return isString(text) && text && text.toLocaleLowerCase() !== 'n/a'
        ? text
        : ''
    },
    /**
     * @returns {string} 编辑按钮的按钮文案
     */
    btnUpdateText() {
      if (!this.hasUpdatePermission && !this.freeUpdate) return ''
      const fixedText =
        isString(this.crudBtnLabel.update) && this.crudBtnLabel.update.trim()
          ? this.crudBtnLabel.update
          : ''
      const text =
        fixedText ||
        (isString(this.crudPmFullname.update) &&
        this.crudPmFullname.update.trim()
          ? this.$_permission_name(this.crudPmFullname.update)
          : isArray(this.crudPmFullname.update) &&
            isString(this.crudPmFullname.update[0]) &&
            this.crudPmFullname.update[0].trim()
          ? this.$_permission_name(this.crudPmFullname.update[0])
          : this.freeUpdate
          ? '编辑'
          : '')

      return isString(text) && text && text.toLocaleLowerCase() !== 'n/a'
        ? text
        : ''
    },
    /**
     * @returns {string} 删除按钮的按钮文案
     */
    btnDeleteText() {
      if (!this.hasDeletePermission && !this.freeDelete) return ''
      const fixedText =
        isString(this.crudBtnLabel.delete) && this.crudBtnLabel.delete.trim()
          ? this.crudBtnLabel.delete
          : ''
      const text =
        fixedText ||
        (isString(this.crudPmFullname.delete) &&
        this.crudPmFullname.delete.trim()
          ? this.$_permission_name(this.crudPmFullname.delete)
          : isArray(this.crudPmFullname.delete) &&
            isString(this.crudPmFullname.delete[0]) &&
            this.crudPmFullname.delete[0].trim()
          ? this.$_permission_name(this.crudPmFullname.delete[0])
          : this.freeDelete
          ? '删除'
          : '')

      return isString(text) && text && text.toLocaleLowerCase() !== 'n/a'
        ? text
        : ''
    },
    /**
     * @returns {string} 批量删除按钮的按钮文案
     */
    btnMultiDelText() {
      if (!this.hasMultiDelPermission && !this.freeMultiDel) return ''
      const fixedText =
        isString(this.crudBtnLabel.multiDel) &&
        this.crudBtnLabel.multiDel.trim()
          ? this.crudBtnLabel.multiDel
          : ''
      const text =
        fixedText ||
        (isString(this.crudPmFullname.multiDel) &&
        this.crudPmFullname.multiDel.trim()
          ? this.$_permission_name(this.crudPmFullname.multiDel)
          : isArray(this.crudPmFullname.multiDel) &&
            isString(this.crudPmFullname.multiDel[0]) &&
            this.crudPmFullname.multiDel[0].trim()
          ? this.$_permission_name(this.crudPmFullname.multiDel[0])
          : this.freeMultiDel
          ? '批量删除'
          : '')

      return isString(text) && text && text.toLocaleLowerCase() !== 'n/a'
        ? text
        : ''
    },
    operationBtnIcons() {
      return {
        create: 'i-add',
        multiDel: 'i-delete',
        ...this.btnIcons
      }
    },
    /**
     * @returns {object}
     */
    crudHandlerConfig() {
      return isPlainObject(this.crudHandler) &&
        isPlainObject(this.crudHandler.config) &&
        Object.keys(this.crudHandler.config).length
        ? { ...DEFAULT_CRUD_HANDLER_CONF, ...this.crudHandler.config }
        : { ...DEFAULT_CRUD_HANDLER_CONF }
    },
    /**
     * @return {boolean} 列表查询是否需要外部处理
     */
    externalQuery() {
      return typeof this.crudHandler.read === 'function'
    },
    /**
     * @return {boolean} 创建是否需要外部操作
     */
    externalCreate() {
      return typeof this.crudHandler.create === 'function'
    },
    /**
     * @return {boolean} 更新是否需要外部操作
     */
    externalUpdate() {
      return typeof this.crudHandler.update === 'function'
    },
    /**
     * @return {boolean} 删除是否需要外部操作
     */
    externalDelete() {
      return typeof this.crudHandler.delete === 'function'
    },
    /**
     * @return {boolean} 批量删除是否需要外部操作
     */
    externalMultiDel() {
      return typeof this.crudHandler.multiDel === 'function'
    },
    /**
     * @return {boolean} 外置的 CRUD 处理器是否包含 API 请求
     */
    externalUseApi() {
      const { useApi } = this.crudHandlerConfig
      return typeof useApi === 'boolean' ? useApi : true
    },
    /**
     * @return {boolean} 列表查询的外部处理是否包含了网络请求
     */
    externalRemoteQuery() {
      return this.externalUseApi && this.externalQuery
    },
    /**
     * @return {boolean} 创建的外部操作是否包含了网络请求
     */
    externalRemoteCreate() {
      return this.externalUseApi && this.externalCreate
    },
    /**
     * @return {boolean} 更新的外部操作是否包含了网络请求
     */
    externalRemoteUpdate() {
      return this.externalUseApi && this.externalUpdate
    },
    /**
     * @return {boolean} 删除的外部操作是否包含了网络请求
     */
    externalRemoteDelete() {
      return this.externalUseApi && this.externalDelete
    },
    /**
     * @return {boolean} 批量删除的外部操作是否包含了网络请求
     */
    externalRemoteMultiDel() {
      return this.externalUseApi && this.externalMultiDel
    },
    /**
     * @return {boolean} 是否存在固定列
     */
    hasFixedColumns() {
      if (this.tableColumns.find((v) => v.type === 'expand')) return false
      return this.tableColumns.some(
        (c) =>
          c?.type ||
          c.prop === 'operation' ||
          ['left', 'right'].includes(c?.fixed)
      )
    },
    maxHeightWrapper() {
      if (this.tableHeight) return undefined
      else return 400
    }
  },
  mounted() {
    this.$halt(16, () => {
      Object.entries(this.$slots).forEach(([k, v]) =>
        this.$set(this.slots, k, !!v)
      )
      Object.entries(this.$scopedSlots).forEach(([k, v]) =>
        this.$set(this.slots, k, !!v)
      )
    })

    this.$halt(320, () => {
      this.$table.recalculate(true) // 试图防止初始化列宽异常
    })
  },

  beforeUpdate() {
    this.clearVxeTableValidate()
  },
  methods: {
    scrollTable(options) {
      this.$emit('scrollTable', options)
    },
    rowStyle({ row }) {
      if (row.color && this.renderRowColor) {
        return {
          backgroundColor: row.color
        }
      }
    },
    loading(loading, payload = { type: 'table' }) {
      if (payload?.type === 'button') {
        this.btnSaveLoading = loading
      } else this.$emit('update:tableLoading', loading)
    },
    /**
     * @description 清除vxe-table中的表格校验
     * @see bug:4061
     */
    clearVxeTableValidate() {
      if (this.flags.validError) {
        this.$table.clearValidate()
        /**
         * flags.validError: 是否存在vxe-table表格的校验提示;true表示有错误提示，false表示无
         */
        this.flags.validError = false
      }
    },

    /**
     * 实现 v-model 双向绑定数据
     * @emits input
     * @param {{source:string,data?:Array<any>}}
     */
    async emitData(payload = { data: undefined, source: 'unknown' }) {
      const { data, source = '' } = payload || {}
      let tableData = []
      if (isArray(data)) {
        tableData = data
      } else {
        if (this.$table) {
          const { fullData } = this.$table.getTableData()
          tableData = this.treeDataNeedTransform
            ? vxeFlatten([...fullData], {
                children: this.treeChildrenField
              })
            : [...fullData]
        } else {
          console.warn('【BaseInlineEditTable】: "$table" never init yet.')
        }
      }
      this.$emit('input', tableData)
      console.info(`【BaseInlineEditTable】: sync data by ${source}`)
    },
    /**
     * 内部操作栏操作列按钮点击
     * @param {('insert'|'save'|'edit''cancel'|'delete'|'append'|'')} [button='']
     * @param {(object|null)} scope
     */
    async onClick(button, scope) {
      this.flags.buttonClicked = button
      if (
        button !== 'cancel' &&
        (!this.removeTemporaryDataStorage || !this.temporaryDataStorage)
      ) {
        console.log('👀LOG -> this.checkBeforeEdit2:', this.checkBeforeEdit)
        // const validateRow = this.checkBeforeEdit
        //   ? await this.validateRow(null, {
        //       source: `onClick:${button}`
        //     })
        //   :
        const validateRow = {
          result: true
        }

        if (!validateRow.result) {
          console.log(
            '【BaseInlineEditTable】"validateRow" error "onClick":',
            button,
            scope,
            validateRow.err
          )
          const key = Object.keys(validateRow?.err?.[0])?.[0]
          /* 处理校验自定义报错提醒 */
          const ruleConf = validateRow.err?.[0][key]?.[0]?.rule ?? {}
          const msg =
            ruleConf?.content ?? ruleConf?.message ?? '请先通过当前表格数据校验'
          return void this.$message.error(msg)
        }
      }

      switch (button) {
        case 'insert':
          await this.insertRow()
          break
        case 'save':
          await this.saveRow(scope)
          break
        case 'edit': {
          if (this.canEditRow(scope) === true) {
            await this.editRow(scope)
          } else console.warn('【BaseInlineEditTable】: disable edit')
          break
        }
        case 'cancel':
          await this.cancelEditRow(scope)
          break
        case 'multi-del':
          await this.removeRow(scope)
          break
        case 'delete':
          if (this.canDeleteRow(scope) === true) {
            await this.removeRow(scope)
          } else console.warn('【BaseInlineEditTable】: disable delete')
          break
        case 'append':
          await this.appendChildRow(scope)
          break
        default:
          console.error('【BaseInlineEditTable】: Unknown "button"', button)
          break
      }
    },
    /**
     * 保存行之前可能需要在外部根据业务逻辑进行突变
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {string} [hookFnName=''] 方法名
     * @returns {Promise<any>} row
     */
    async handleSaveRow(scope, hookFnName = '') {
      if (!hookFnName || typeof this[hookFnName] !== 'function') return scope

      try {
        const [error, result] = await getSyncAsyncReturns(
          this[hookFnName],
          null, // context
          scope
        )

        if (error) {
          console.error('【BaseInlineEditTable】:', hookFnName, error, result)
        } else {
          if (hookFnName === 'beforeSaveRow' && result === false) {
            // beforeSaveRow 可以拦截保存行
            console.warn('【BaseInlineEditTable】stop save')
            return false
          } else {
            if (isPlainObject(result)) {
              return result
            } else {
              console.error(
                `【BaseInlineEditTable】: Illeagal result acceptted from "${hookFnName}"`,
                result
              )
            }
          }
        }
      } catch (error) {
        console.error(error)
      }

      return scope
    },
    /**
     * 插入空白行
     * @returns {(object|null)}
     */
    async insertRow() {
      // 是否开启临时数据存储
      if (this.temporaryDataStorage) {
        this.$emit('addTemporaryDataStorage')
        return
      }

      if (
        (this.tableLoading ||
          this.flags.emptyRowRemoving ||
          this.flags.illegalRowRemoving ||
          this.flags.tableEditing) &&
        !this.ignoreEdit
      ) {
        console.groupCollapsed('【BaseInlineEditTable】: Busy now')
        console.table({
          tableLoading: this.tableLoading,
          emptyRowRemoving: this.flags.emptyRowRemoving,
          illegalRowRemoving: this.flags.illegalRowRemoving,
          tableEditing: this.flags.tableEditing
        })
        console.groupEnd()
        return null
      }

      if (
        typeof this.canInsertRow === 'function' &&
        (await this.canInsertRow()) === false
      ) {
        console.warn(
          '【BaseInlineEditTable】: disable insert by "canInsertRow"'
        )
        return null
      }

      try {
        if (!(await this.validateRow(null, { source: 'insertRow' })).result) {
          console.log(
            '【BaseInlineEditTable】"validateRow" error when "insertRow"'
          )
          this.$message.error('请先通过当前表格数据校验')
          return null
        }
        let record = this.isTree
          ? {
              [this.treeParentField]: null // 需要声明父节点 null
            }
          : {}

        typeof this.beforeInsertRow === 'function'
          ? (record =
              (
                await getSyncAsyncReturns(this.beforeInsertRow, null, record)
              )[1] || record)
          : null
        let { row } = await this.$table.insertAt(record, -1)

        typeof this.afterInsertRow === 'function'
          ? (row =
              (await getSyncAsyncReturns(this.afterInsertRow, null, row))[1] ||
              row)
          : null

        this.setRowExternal({ row, key: 'isInsert', value: true })
        this.setRowExternal({ row, key: 'isCreate', value: true })

        await this.$halt(16)
        await this.$table.setActiveRow(row)
        typeof this.$table.setEditRow === 'function' &&
          (await this.$table.setEditRow(row))
        return row
      } catch (error) {
        console.error('【BaseInlineEditTable】: ', error)
        return null
      }
    },
    /**
     * 编辑行
     * @param {*} scope - table row,columns,...
     */
    async editRow(scope) {
      if (this.rowEditing && this.rowEditingDisabledOtherRow) {
        return this.$message.error('请先保存或取消正在编辑的数据')
      }
      try {
        const { row } = scope
        this.setRowExternal({ row, key: 'isCreate', value: false })
        await this.$halt(16)
        this.emitData({ source: 'editRow' })
        await this.$halt(16)
        await this.$table.setActiveRow(row)
        typeof this.$table.setEditRow === 'function' &&
          (await this.$table.setEditRow(row))

        this.checkBeforeEdit && this.validateRow(row, { source: 'editRow' })
        this.rowEditing = true
      } catch (error) {
        console.error('【BaseInlineEditTable】: ', error)
      }
    },
    /**
     * 取消行编辑
     * @param {*} scope - table row,columns,...
     */
    async cancelEditRow(scope) {
      try {
        this.flags.skipValidation = true
        const { row } = scope
        const isCreate = this.getRowExternal({ row, key: 'isCreate' })
        if (isCreate) {
          await this.removeRow(scope, { silent: true, removeBy: 'cancel' })
        } else {
          // TODO: 目前暂未开发在填入附件信息后，点击取消还原原本的附件信息的功能
          const isUpdated = this.isUpdateByRow(row)
          if (isUpdated) await this.revertRow(row)
        }
        this.clearTableActived(scope, { by: 'cancel' })
        this.rowEditing = false
      } catch (error) {
        console.error('【BaseInlineEditTable】: "cancelEditRow"', error)
      }
    },
    /**
     * @returns {boolean}
     */
    isUpdateByRow(row) {
      // 使用vxeTable 方法去判断是否有更新
      const vxeUpdated = this.$table.isUpdateByRow(row)
      if (vxeUpdated === true) return true
      // 文件上传时，vxeTable的isUpdateByRow() 监测不到更新，需要单独判断
      const fileChanged = this.getRowExternal({ row, key: 'fileChanged' })
      if (['add', 'remove'].includes(fileChanged)) return true
      // 其他自定义直接给row中的属性赋值，vxeTable的isUpdateByRow()监测不到更新，需要单独判断
      const customUpdate = this.getRowExternal({ row, key: 'customUpdate' })
      if (customUpdate === true) return true

      return false
    },
    /**
     * 保存行
     * @param {*} scope - table row,columns,...
     * @param {{noEmit:boolean}} payload
     * @returns {Promise<any>} updated source socpe
     * @emits save-row-success 不区分 create/update, 都是保存
     * @emits save-row-failed 不区分 create/update, 都是保存
     * @emits save-row-error 不区分 create/update, 都是保存
     * @emits save-row-valid 保存校验
     */
    async saveRow(scope, payload = {}) {
      let _scope = scope

      const { row } = _scope

      const { noEmit = false } = payload
      if (this.ignoreEdit) {
        this.emitData({ source: 'saveRow' })
        this.$emit('save-row-success', _scope)
        return
      }
      try {
        // 保存时做额外校验
        if (this.$listeners && this.$listeners['save-row-valid']) {
          await new Promise((resolve, reject) => {
            this.$emit('save-row-valid', _scope, (error) => {
              if (error) reject(error)
              // 抛出异常
              else resolve()
            })
          })
        }
        this.$emit('save-row-success', _scope)

        const valid = (await this.validateRow(row, { source: 'saveRow' }))
          ?.result
        if (!valid) {
          console.log(
            '【BaseInlineEditTable】"validateRow" error when "saveRow"'
          )
          _scope.validError = true
          return _scope
        } else _scope.validError = false

        const isCreate = this.getRowExternal({ row, key: 'isCreate' })
        console.log(
          '【BaseInlineEditTable】: current row is create',
          isCreate,
          row
        )

        const isUpdated = this.isUpdateByRow(row)
        console.log(
          '【BaseInlineEditTable】: current row is updated',
          isUpdated,
          row
        )

        if (!isUpdated && !isCreate) {
          return this.clearTableActived(_scope, { by: 'save-row' })
        }

        if (this.isTree) {
          const { parentRow, silbingsRows } = this.getTreeRelatedRows(scope)
          _scope.parentRow = parentRow
          _scope.silbingsRows = silbingsRows
          console.log(
            '【BaseInlineEditTable tree】: inject "parentRow" and "silbingsRows" into scope',
            parentRow,
            silbingsRows
          )
        }

        const beforeSaveRowResult = await this.handleSaveRow(
          _scope,
          'beforeSaveRow'
        )
        if (beforeSaveRowResult === false) return _scope
        else _scope = beforeSaveRowResult || _scope
        const success = await this[
          isCreate ? 'createTableRow' : 'updateTableRow'
        ](_scope.row, _scope)
        if (success) {
          //  刷新
          // eslint-disable-next-line no-unused-vars
          const { type, data } = await this.refresh(
            isCreate ? 'create' : 'update',
            'soft',
            _scope
          )

          /**
           * @type {object}
           * @description here `data` is null or record(the objet returned that from  $table.getActiveRecord() or $table.getEditRecor())
           */
          _scope = data || _scope

          // 回调
          _scope = (await this.handleSaveRow(_scope, 'afterSaveRow')) || _scope
          await this.$halt(16)
          this.emitData({ source: 'saveRow' })

          if (
            this.flags[isCreate ? 'createSaveError' : 'updateSaveError'] ===
            true
          ) {
            this.flags[isCreate ? 'createSaveError' : 'updateSaveError'] = false
          }
          noEmit !== true &&
            this.$emit('save-row-success', {
              rowid: _scope.rowid,
              rowIndex: _scope.rowIndex,
              $rowIndex: _scope.$rowIndex,
              columnIndex: _scope.columnIndex,
              $columnIndex: _scope.$columnIndex,
              row: _scope.row,
              $table: this.$table
            })

          this.clearTableActived(_scope, { by: 'save-row-success' })
        } else {
          noEmit !== true &&
            this.$emit('save-row-failed', {
              rowid: _scope.rowid,
              rowIndex: _scope.rowIndex,
              $rowIndex: _scope.$rowIndex,
              columnIndex: _scope.columnIndex,
              $columnIndex: _scope.$columnIndex,
              row: _scope.row,
              $table: this.$table
            })
          this.$emit('update-side-bar')
        }
      } catch (error) {
        noEmit !== true && this.$emit('save-row-error', error)
        const isCreate = this.getRowExternal({
          row: _scope.row,
          key: 'isCreate'
        })
        this.flags[isCreate ? 'createSaveError' : 'updateSaveError'] = true
        console.error('【BaseInlineEditTable】: "saveRow"', error)
      } finally {
        this.rowEditing = false
      }
      return _scope
    },

    /**
     * 删除行
     * @param {*} scope - table row,columns,...
     * @param {{silent:boolean,removeBy:('normal'|'cancel')}} payload
     * @emits before-remove-row
     * @emits before-remove-rows
     * @emits remove-error
     */
    async removeRow(scope, payload = { silent: false, removeBy: 'normal' }) {
      /** 开启删除临时数据 */
      if (this.removeTemporaryDataStorage) {
        this.$emit('removeTemporaryDataStorage')
        return
      }
      try {
        // if (!this.tableData.length) return void console.warn('empty data')

        const { silent = false, removeBy = 'normal' } = payload || {}

        const row = scope?.row
        const rows =
          this.checkboxConf.reserve === true
            ? [
                ...this.$table.getCheckboxRecords(false),
                ...this.$table.getCheckboxReserveRecords(false)
              ]
            : [...this.$table.getCheckboxRecords(false)]

        if (silent !== true) {
          if (this.flags.tableEditing === true) {
            return void console.warn('tableEditing', this.flags.tableEditing)
          }

          if (!row) {
            if (rows.length === 0) {
              console.info(
                '【BaseInlineEditTable】: empty selection for "removeRow"'
              )
              return void this.$message.error('请先勾选一项要操作的数据')
            }
          }
          if (
            this.showRemoveRowConfirmBox &&
            !(await this.confirmOperate('删除'))
          ) {
            return
          }
        }
        let validDelete = true
        if (typeof this.beforeRemoveRow === 'function') {
          validDelete = await this.beforeRemoveRow(scope)
        }

        if (!validDelete) return
        this.flags.skipValidation = true

        if (row) {
          this.$emit('before-remove-row', {
            row: deepClone(scope.row),
            $table: this.$table
          })
          this.singleRemove(row, scope, { silent, removeBy })
        } else {
          this.$emit('before-remove-rows', {
            rows,
            $table: this.$table
          })
          this.multipleRemove(rows, { silent, removeBy })
        }
      } catch (error) {
        console.error(
          '【BaseInlineEditTable】: "removeRow"',
          scope,
          payload,
          error
        )
        this.$emit('remove-error', error)
      }
    },
    /**
     * 单行删除
     * @param {*} row - table row
     * @param {*} scope - table row,columns,...
     * @param {{silent:boolean}} payload
     */
    async singleRemove(row, scope, payload) {
      const removeFile = this.getRowExternal({ row, key: 'removeFile' })
      if (typeof removeFile === 'function') await removeFile()

      const success = await this.deleteTableRow(row, scope, payload)
      if (success) {
        if (this.afterRemoveRefresh === true) {
          await this.refresh('delete', 'soft', { ...scope, ...payload })
        }
        if (this.flags.deleteError === true) this.flags.deleteError = false

        await this.$emit('after-remove-row', {
          row,
          scope,
          payload,
          $table: this.$table
        })
      } else this.flags.deleteError = true

      this.$message.success(
        payload.removeBy === 'cancel' ? '取消成功！' : '删除成功！'
      )
    },

    /**
     * 批量删除
     * @param {Array}  - table rows
     * @param {{silent:boolean}} payload
     */
    async multipleRemove(rows, payload) {
      const success = await this.multiDelTableRow(rows)
      if (success) {
        if (this.afterRemoveRefresh === true) {
          await this.refresh('multi-del', 'soft', { rows, ...payload })
        }
        if (this.flags.multiDelError === true) this.flags.multiDelError = false

        await this.$emit('after-remove-rows', {
          rows,
          payload,
          $table: this.$table
        })
      } else this.flags.multiDelError = true

      this.$message.success('删除成功！')
    },

    /**
     * @emits toggle-tree-expand
     */
    async onToggleTreeExpand({ expanded, row, ...scope }) {
      this.$emit('toggle-tree-expand', { expanded, row, ...scope })

      if (expanded === true) {
        const loaded = await this.onTreeRowLazyLoaded(row)
        if (loaded) this.emitData({ source: 'onToggleTreeExpand' })
        else console.warn('【BaseInlineEditTable tree】: lazy load error')
      }
    },
    /**
     * @returns {boolean}
     */
    // interceptToggleTreeExpand({ expanded, row, column, columnIndex }) {
    //   console.log(expanded, row, column, columnIndex)
    //   return true
    // },
    /**
     * 判断行是否是树节点父级
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @returns {boolean}
     */
    isTreeParentRow(row) {
      try {
        if (!this.isTree) return false

        if (!isPlainObject(row)) return false

        const noParent =
          row[this.treeParentField] === null ||
          row[this.treeParentField] === undefined ||
          row[this.treeParentField] === ''

        const hasChild = row[this.treeHasChildField]

        const children = row[this.treeChildrenField]

        const isBaseParent = this.treeIsLazy
          ? hasChild || noParent
          : (isArray(children) && children.length) || noParent

        let isParentSpecial = false
        if (this.treeIsParentExternalCondition) {
          isParentSpecial = this.treeIsParentExternalCondition({ row })
          if (typeof isParentSpecial !== 'boolean') {
            console.error(
              '【BaseInlineEditTable tree】: "isParent" of "treeSpecial" must be sync func and return boolean'
            )
            isParentSpecial = false
          }
        }
        return !!isBaseParent || !!isParentSpecial
      } catch (error) {
        console.error(error)
        return false
      }
    },
    /**
     * @param {*} scope - table row,columns,...
     * @returns {boolean} 树表格行是否隐藏编辑按钮
     */
    hideUpdateRow(scope) {
      try {
        if (this.isTree) {
          const { row } = scope
          /**
           * treeDisableEditRow
           * - all:          禁止所有树节点编辑
           * - never:        不禁止树节点编辑
           * - parent:       禁止父节点编辑
           * - child:        禁止子节点编辑
           * - hasChildren:  禁止有子节点的节点编辑
           */
          switch (this.treeDisableEditRow) {
            case 'all':
              return true
            case 'never':
              return false
            case 'parent':
              return this.isTreeParentRow(row)
            case 'child':
              return !this.isTreeParentRow(row)
            case 'hasChildren': {
              const hasChild = row[this.treeHasChildField]
              const children = row[this.treeChildrenField]
              return this.treeIsLazy
                ? typeof hasChild === 'boolean'
                  ? hasChild
                  : false
                : isArray(children) && children.length > 0
            }
            default:
              console.error('Illegal "disableEditTreeRow"')
          }
        }
      } catch (error) {
        console.error(error)
      }
      return false
    },
    hideRemoveRow(scope) {
      let enableDelete = true
      try {
        if (this.isTree) {
          if (this.treeAllowToRemove) {
            enableDelete = this.treeAllowToRemove({
              isParent: this.isTreeParentRow(scope.row),
              ...scope
            })
            if (typeof enableDelete !== 'boolean') {
              console.error(
                '【BaseInlineEditTable tree】: "allowToRemove" of "treeSpecial" must be sync func and return boolean'
              )
              enableDelete = true
            }
          }
        }
      } catch (error) {
        console.error('【BaseInlineEditTable tree】: ' + error)
      }

      return !enableDelete
    },
    /**
     * @param {*} scope - table row,columns,...
     * @returns {boolean} 是否允许添加子集
     */
    allowAppendChild(scope) {
      let enableAppend = false

      try {
        if (this.isTree) {
          if (this.treeAllowToAppend) {
            enableAppend = this.treeAllowToAppend({
              isParent: this.isTreeParentRow(scope.row),
              ...scope
            })

            if (typeof enableAppend !== 'boolean') {
              console.error(
                '【BaseInlineEditTable tree】: "allowToAppend" of "treeSpecial" must be sync func and return boolean'
              )
              enableAppend = false
            }
          }
        }
      } catch (error) {
        console.error('【BaseInlineEditTable tree】: ' + error)
      }
      return enableAppend
    },

    /**
     * 插入子集
     * @param {*} scope - table row,columns,...
     * @see https://xuliangzhan_admin.gitee.io/vxe-table/#/table/tree/crud
     */
    async appendChildRow({ row: parentRow }) {
      try {
        if (
          !(await this.validateRow(null, { source: 'appendChildRow' })).result
        ) {
          console.log(
            '【BaseInlineEditTable】"validateRow" error when "appendChildRow"'
          )
          return void this.$message.error('请先通过当前表格数据校验')
        }

        let needWaitExpand = false
        const treeHasExpanded =
          this.$table.isTreeExpandLoaded(parentRow) ||
          this.$table.isTreeExpandByRow(parentRow)

        let children = parentRow[this.treeChildrenField]

        if (!treeHasExpanded && !this.flags.treeExpanding) {
          this.flags.treeExpanding = true
          const hasChild = parentRow[this.treeHasChildField]
          if (hasChild) {
            if (
              (!isArray(children) || (isArray(children) && !children.length)) &&
              typeof this.treeConf.loadMethod === 'function'
            ) {
              // 加载子集
              needWaitExpand = true
              await this.$table.reloadTreeExpand(parentRow)
            }
          }
          if (isArray(children) && children.length) {
            // 展开子集
            needWaitExpand = true
            await this.$table.setTreeExpand(parentRow, true)
          }
          if (needWaitExpand) await this.onTreeRowLazyLoaded(parentRow)
        }

        children = parentRow[this.treeChildrenField] // 节点展开后

        let record = {
          [this.treeParentField]: parentRow[this.treeRowField] // 需要指定父节点，自动插入该节点中
        }
        typeof this.beforeAppendRow === 'function'
          ? (record =
              (
                await getSyncAsyncReturns(this.beforeAppendRow, null, {
                  row: record,
                  parentRow,
                  silbingsRows: children
                })
              )[1] || record)
          : null
        let { row: childRow } = await this.$table.insertAt(record, -1)
        typeof this.afterAppendRow === 'function'
          ? (childRow =
              (
                await getSyncAsyncReturns(this.afterAppendRow, null, {
                  row: childRow,
                  parentRow,
                  silbingsRows: children
                })
              )[1] || childRow)
          : null

        this.setRowExternal({ row: childRow, key: 'isCreate', value: true })
        this.setRowExternal({
          row: childRow,
          key: this.treeParentField,
          value: parentRow[this.treeRowField]
        })
        this.emitData({ source: 'appendChildRow' })
        await this.$halt(16)
        await this.$table.setTreeExpand(parentRow, true) // 再次展开
        await this.$halt(16)
        await this.$table.setActiveRow(childRow) // 插入子节点
        typeof this.$table.setEditRow === 'function' &&
          (await this.$table.setEditRow(childRow))
      } catch (error) {
        console.error('【BaseInlineEditTable tree】"appendChildRow": ', error)
      }
      this.flags.treeExpanding = false
    },
    /**
     * 轮询树懒加载展开状态
     * @param {*} parentRow
     * @returns {boolean}
     */
    async onTreeRowLazyLoaded(parentRow) {
      // if (!this.tableLoading) this.loading(true)
      let treeHasExpanded = ((parentRow) => () => {
        return (
          this.$table.isTreeExpandLoaded(parentRow) ||
          this.$table.isTreeExpandByRow(parentRow)
        )
      })(parentRow)
      // 轮询当前节点展开状态
      const end = await poll({
        target: treeHasExpanded,
        timeout: POLL_TIMEOUT.baseInlineEditTableTreeExpand,
        expect: true
      })
      treeHasExpanded = null
      // this.loading(false)

      this.$halt(16, () => {
        isArray(parentRow?.[this.treeChildrenField]) &&
        parentRow[this.treeChildrenField].length > 0
          ? parentRow[this.treeChildrenField].forEach((row) => {
              this.setRowExternal({
                row,
                key: 'hasSaved',
                value: true
              })
            })
          : null
      })

      return !!end
    },

    /**
     * 局部加载行数据并恢复到初始状态
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {TableColumn} column 表格列
     * @param {object=} record 新数据
     * @param {string=} field 指定字段名
     * @returns {Promise<any>} scope
     */
    async updateRow(row, column, record, field) {
      try {
        await this.$table.reloadRow(row, record, field)
        await this.$table.updateStatus({ row, column })
        return this.getActiveRecord() ?? row
      } catch (error) {
        console.error('【BaseInlineEditTable】: ', error)
      }
    },

    /**
     * 回滚行
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {field?:string,source:('table-select-blur'|undefined)} options
     * @description // TODO:还原数据, 树结构表格不支持 revertData API
     */
    async revertRow(row, options = {}) {
      if (this.isTree) {
        const { source } = options
        if (source === 'table-select-blur') {
          return void console.info(
            '"table-select-blur" from "VxeTableSelect", not "refreshTreeTable"'
          )
        }
        await this.refreshTreeTable(row, 'positive', { type: 'cancel' })
      } else {
        const { field } = options
        await this.$table.revertData(row, field)
        await this.$halt(16)
        this.emitData({ source: 'revertRow' })
      }
    },
    /**
     * 表格：查 crudHandler.read
     * @emits update:tableLoading
     * @emits read-success
     * @emits read-failed
     * @emits read-error
     *
     * @returns {Promise<Array>}
     */
    async fetchTableData() {
      let data = []
      if (!this.allowRead || !this.externalQuery) return this.tableData
      try {
        this.loading(true)

        const [error, result] = await getSyncAsyncReturns(this.crudHandler.read)
        if (error) {
          this.flags.readError = true
          this.$emit('read-error', error)
          this.loading(false)
          console.error('【BaseInlineEditTable crud】: ', error)
        } else {
          if (result && isArray(result)) {
            if (this.flags.readError === true) this.flags.readError = false
            // if (result.length)
            data = result
            this.$emit('read-success', data)
          } else {
            this.flags.readError = true
            this.$emit('read-failed', result)
            console.error(
              '【BaseInlineEditTable crud】: Only support accept Promise<array> or array from crudHandler.read',
              result
            )
          }
        }
      } catch (error) {
        this.$emit('read-error', error)
        this.flags.readError = true
        console.error('【BaseInlineEditTable crud】: ' + error)
      } finally {
        this.tableLoading ? this.loading(false) : null
      }
      return data
    },

    /**
     * 表格：增 crudHandler.read
     * @emits update:tableLoading
     *
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {*} scope - table row,columns,...
     * @returns {Promise<boolean>}
     *
     */
    async createTableRow(row, scope) {
      if (!this.allowCreate) {
        console.error(
          '【BaseInlineEditTable crud】: not allow create table records'
        )
        return false
      }
      if (!this.externalCreate) {
        console.info(
          '【BaseInlineEditTable crud】: create table records locally'
        )
        return true
      }
      return await this.getExternalHandlerResult('create', row, scope)
    },
    /**
     * 表格：改 crudHandler.update
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {*} scope - table row,columns,...
     * @returns {Promise<boolean>}
     *
     */
    async updateTableRow(row, scope) {
      if (!this.allowUpdate) {
        console.error(
          '【BaseInlineEditTable crud】: not allow update table records'
        )
        return false
      }
      if (!this.externalUpdate) {
        console.info(
          '【BaseInlineEditTable crud】: update table record locally'
        )
        return true
      }
      return await this.getExternalHandlerResult('update', row, scope)
    },
    /**
     * 表格：删 crudHandler.delete
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {*} scope - table row,columns,...
     * @returns {Promise<boolean>}
     */
    async deleteTableRow(row, scope, payload) {
      if (!this.allowDelete && payload?.removeBy !== 'cancel') {
        console.error(
          '【BaseInlineEditTable crud】: not allow delete table records'
        )
        return false
      }

      if (!this.externalDelete) {
        console.info(
          '【BaseInlineEditTable crud】: delete table record locally'
        )
        await this.$table.remove(row)
        return true
      }
      const hasSaved = this.getRowExternal({ row, key: 'hasSaved' })
      let remove = true
      if (hasSaved && (this.externalDelete || this.externalRemoteDelete)) {
        remove = await this.getExternalHandlerResult('delete', row, scope)
      }
      return remove
    },
    /**
     * 表格：删 crudHandler.delete
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {*} scope - table row,columns,...
     * @returns {Promise<boolean>}
     */
    async multiDelTableRow(rows, scope) {
      if (!this.allowMultiDel) {
        console.error(
          '【BaseInlineEditTable crud】: not allow multi-del records'
        )
        return false
      }

      if (!this.externalMultiDel) {
        console.info(
          '【BaseInlineEditTable crud】: multiple delete table record locally'
        )
        await this.$table.remove(rows)
        return true
      }

      return await this.getExternalHandlerResult('multiDel', rows, scope)
    },
    /**
     * @param {('create'|'update'|'delete'|'multiDel')} cud
     * @param {TableRow|Array<TableRow>} row 表格行 row or rows(row array)
     * @param {*} scope - table row,columns,...
     *
     * @emits update:tableLoading
     *
     * @emits create-success
     * @emits create-failed
     * @emits create-error
     *
     * @emits update-success
     * @emits update-failed
     * @emits update-error
     *
     * @emits delete-success
     * @emits delete-failed
     * @emits delete-error
     *
     * @emits multiDel-success
     * @emits multiDel-failed
     * @emits multiDel-error
     */
    async getExternalHandlerResult(cud, row, scope) {
      let status = false
      try {
        this.loading(true)
        const [error, result] = await getSyncAsyncReturns(
          this.crudHandler[cud],
          null,
          row,
          scope
        )
        if (error) {
          this.$emit(`${cud}-error`, error)
          this.loading(false)
          this.flags[`external${uppercaseFirst(cud)}Error`] = true
          console.error('【BaseInlineEditTable crud】: ', error)
        } else {
          if (typeof result === 'boolean') {
            status = result
            this.$emit(`${cud}-success`)
            this.flags[`external${uppercaseFirst(cud)}Error`] = false
          } else {
            this.$emit(`${cud}-failed`, result)
            this.flags[`external${uppercaseFirst(cud)}Error`] = true
            console.error(
              `【BaseInlineEditTable crud】: Only support accept Promise<boolean> or boolean  from crudHandler.${cud}`,
              result
            )
          }
        }
      } catch (error) {
        this.$emit(`${cud}-error`, error)
        this.flags[`external${uppercaseFirst(cud)}Error`] = true
        console.error('【BaseInlineEditTable crud】: ' + error)
      } finally {
        this.tableLoading ? this.loading(false) : null
      }
      return status
    },

    /**
     * 弹窗二次确认操作
     * @param {string} operation 操作中文描述，如：'创建', '删除', '更新' ...
     * @returns {Promise<boolean>}
     */
    async confirmOperate(operation) {
      return new Promise((resolve) => {
        this.$confirm(this.deleteText || `确认要执行${operation}吗?`, '提示', {
          confirmButtonText: '确  定',
          cancelButtonText: '取 消',
          type: this.deleteType,
          customClass: 'msgbox'
        })
          .then(() => resolve(true))
          .catch(() => resolve(false))
      })
    },
    /**
     * @param {*} scope
     */
    getTreeRelatedRows(scope, rowField = '', parentField, childrenField = '') {
      let parentRow = null
      let silbingsRows = []

      if (!this.tableData.length) {
        console.warn(
          `【BaseInlineEditTable】no related rows becasue the empty "tableData"`
        )
        return { parentRow, silbingsRows }
      }

      const pid = parentField || this.treeParentField

      const parentId = this.getRowExternal({ row: scope?.row, key: pid })
      if (!parentId) {
        console.error(
          `【BaseInlineEditTable】losed "parentId" of row's external`,
          scope
        )
        return { parentRow, silbingsRows }
      }
      const id = rowField || this.treeRowField
      const children = childrenField || this.treeChildrenField

      parentRow = this.tableData.find((r) => r[id] === parentId) ?? null

      if (parentRow) {
        silbingsRows =
          parentRow?.[children]?.filter(
            (r) => scope.row?.[id] && r[id] !== scope.row[id]
          ) ?? []
      }

      return { parentRow, silbingsRows }
    },
    /**
     * 判断行是否正在编辑
     * @param {TableRow} row 表格行 row
     * @returns {boolean}
     */
    isEditing(row) {
      try {
        return this.$table && typeof this.$table.isActiveByRow === 'function'
          ? this.$table.isActiveByRow(row)
          : false
      } catch (error) {
        console.error('【BaseInlineEditTable】: ', error)
      }
      return false
    },

    /**
     * 移除空行
     * @emits update:tableLoading
     */
    async removeEmptyRow(scope) {
      let removed = false
      try {
        this.flags.emptyRowRemoving = true
        this.loading(true)
        // eslint-disable-next-line no-unused-vars
        const { _XID, _X_ID, __BIET_ROW_EXTERNAL__, ...cells } = scope.row
        this.rowId ? delete cells[this.rowId] : null
        if (
          Object.values(cells).filter(
            (v) =>
              (!!v || v === 0) &&
              ((isArray(v) && v.length) ||
                (isPlainObject(v) && Object.keys(v).length))
          ).length === 0
        ) {
          await this.removeRow(scope, { silent: true })
          console.log('【BaseInlineEditTable】: remove empty row')
          removed = true
        }
      } catch (error) {
        console.error('【BaseInlineEditTable】: ', error)
      } finally {
        this.loading(false)
        this.$halt(160, () => (this.flags.emptyRowRemoving = false))
      }
      return removed
    },
    /**
     * 移除校验未通过的数据行
     * @emits update:tableLoading
     */
    async removeIllegalRow(scope = undefined) {
      let removed = false
      this.flags.illegalRowRemoving = true
      this.loading(true)
      try {
        await Promise.all(
          scope && isPlainObject(scope) && isPlainObject(scope.row)
            ? [
                this.$table.validate(scope.row)
                // this.$table.validate()
              ]
            : [
                this.$table.fullValidate(true)
                // this.$table.fullValidate()
              ]
        )
      } catch (err) {
        const _err = isArray(err) ? err : isPlainObject(err) ? [err] : []
        if (_err.length) {
          await Promise.all(
            _err.map(
              async (e) =>
                await this.removeRow(Object.values(e)?.[0]?.[0]?.row, {
                  silent: true
                })
            )
          )
          removed = true
        }
      } finally {
        this.loading(false)

        this.$halt(160, () => {
          this.flags.illegalRowRemoving = false
        })
      }

      return removed
    },
    /**
     * 单元格清除聚焦
     */
    async getActiveRecord() {
      return typeof this.$table.getActiveRecord === 'function'
        ? this.$table.getActiveRecord()
        : typeof this.$table.getEditRecord === 'function'
        ? this.$table.getEditRecord()
        : null
    },
    /**
     * 单元格清除聚焦
     * @param {(object|null)} scope
     * @param {{skipValid:boolen,by:string}} payload
     * @emits clear-cell-actived
     */
    async clearTableActived(scope = null, payload = {}) {
      const { skipValid = false, by = 'unknown' } = payload
      console.log(
        `【BaseInlineEditTable】clearTableActived by ${by} and skipValid:${skipValid}`
      )
      if (skipValid === true) this.flags.skipValidation = true

      if (typeof this.$table?.clearActived === 'function') {
        await this.$table.clearActived()
      } else if (typeof this.$table?.clearEdit === 'function') {
        await this.$table.clearEdit()
      }
      this.$emit('clear-cell-actived', scope ?? this.currentEditScope)
      await this.$halt(160)
      return scope ?? this.currentEditScope
    },
    /**
     * 编辑聚焦钩子
     * @emits edit-opened
     */
    onEditOpened(scope) {
      if (!this.isRowAction) return
      if (this.flags.skipValidation) this.flags.skipValidation = false

      try {
        if (this.isTree) {
          this.setRowExternal({
            row: scope.row,
            key: this.treeParentField,
            value: scope.row[this.treeParentField] ?? null
          })
        }
        this.currentEditScope = scope
        this.$emit('edit-actived', scope)
      } catch (e) {
        console.error('【BaseInlineEditTable】onEditOpened: ', e)
      } finally {
        if (!this.flags.isDirty) this.flags.isDirty = true
        if (!this.flags.tableEditing) this.flags.tableEditing = true
      }
    },
    /**
     * 编辑失焦钩子
     * @param {*} scope - table row,columns,...
     *
     * @emits edit-closed
     */
    async onEditClosed(scope) {
      if (!this.isRowAction) return

      try {
        if (
          this.flags.buttonClicked !== 'cancel' &&
          this.flags.buttonClicked !== 'save'
        ) {
          await this.validateRow(scope?.row, { source: 'onEditClosed' })

          if (this.flags.validError) {
            console.log(this.flags.validError)
          }

          const { row } = (await this.saveRow(scope, { noEmit: true })) ?? {}

          this.$emit('edit-closed', { ...scope, row })
        } else {
          this.$emit('edit-closed', { ...scope })
        }
      } catch (error) {
        console.error('【BaseInlineEditTable】onEditClosed: ', error)
      } finally {
        if (this.currentEditScope) this.currentEditScope = null
        if (this.flags.tableEditing) this.flags.tableEditing = false
        if (this.flags.skipValidation) this.flags.skipValidation = false
        if (this.flags.buttonClicked) this.flags.buttonClicked = ''
      }
    },
    onEditDisabled(scope) {
      console.warn(
        '【BaseInlineEditTable】: disabled column edit and emitted【edit-disabled】',
        scope
      )
      this.$emit('edit-disabled', scope)
    },
    /**
     * @emits cell-mouseenter
     */
    onCellEnter(scope) {
      this.hoveredRow = scope.row
      if (!this.flags.mouseOnTableCell) this.flags.mouseOnTableCell = true
      this.$emit('cell-mouseenter', scope)
    },
    /**
     * @emits cell-mouseleave
     */
    onCellLeave(scope) {
      this.hoveredRow = null
      if (this.flags.mouseOnTableCell) this.flags.mouseOnTableCell = false
      this.$emit('cell-mouseleave', scope)
    },
    /**
     * @emits cell-click
     */
    onCellClick(scope) {
      this.currentEditScope = scope
      this.$emit('cell-click', scope)
    },

    onCellSelected(scope) {
      this.currentEditScope = scope
      this.$emit('cell-selected', scope)
    },
    selectChangeEvent(e) {
      this.$emit('checkbox-change', {
        ...e,
        type: 'single'
      })
      /**
       * @description 父组件在baseInlineTable组件（存在树）调用 checkbox-change 调用时会出现勾选后自动折叠树
       */
      this.checkboxData = [...e.records, ...e.reserves]
    },
    selectAllEvent(e) {
      this.$emit('checkbox-change', {
        ...e,
        type: 'all'
      })
      this.$emit('checkbox-all', e)
    },
    handleCheckChange() {
      const $table = this.$table
      const checkedArr = $table.getCheckboxRecords()
      const docHtml = $table.$el.querySelector(
        '.vxe-table--fixed-wrapper .vxe-cell--checkbox'
      )
      if (!docHtml) return void console.warn('vxe-table without checkbox')
      if (checkedArr.length < this.tableData.length) {
        docHtml.classList.add('is--indeterminate')
      }
      if (checkedArr.length === 0) {
        docHtml.classList.remove('is--indeterminate', 'is--checked')
      }
      if (checkedArr.length === this.tableData.length) {
        docHtml.classList.remove('is--indeterminate')
        docHtml.classList.add('is--checked')
      }
    },
    /**
     * @emits resizable-change
     */
    onHeaderDragend({ $rowIndex, column, columnIndex, $columnIndex, $event }) {
      this.$emit('resizable-change', {
        $rowIndex,
        column,
        columnIndex,
        $columnIndex,
        $event
      })
      this.$emit('header-dragend', {
        $rowIndex,
        column,
        columnIndex,
        $columnIndex,
        $event
      })

      const { key, updateColumnWidth } = listHeaderCache
      const _key = key.call(this, this.tableKey)

      debounce(function () {
        updateColumnWidth(
          _key,
          column,
          column?.resizeWidth || column?.width || 'auto'
        )
      }, 400)()
    },
    /* 排序条件触发*/
    onSortChange({ column, property, order, sortBy, sortList, $event }) {
      this.$emit('sort-change', {
        column,
        property,
        order,
        sortBy,
        sortList,
        $event
      })
    },
    /* 复制*/
    goHandleCopy(txt) {
      handleCopy(txt)
    }
  }
}
</script>
<style>
.msgbox {
  width: 450px !important;
}
</style>
<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.base-inline-edit-table {
  @include scrollbar-all-children;
  .table-operation {
    height: fit-content;
    margin-bottom: 12px;
  }
}

:deep(*) {
  @import '~@/plugins/vxe-table/custom-renderers/ElEditSpan/index.scss';
  @import '~@/plugins/vxe-table/custom-renderers/ElFileUploader/index.scss';
}
:deep(.table-na-placeholder) {
  color: #ccc;
}

:deep(.vxe-cell-upper-corner-mark) {
  top: -5px;
  position: absolute;
  border-width: 5px;
  border-style: solid;
  transform: rotate(45deg);
}

:deep(.vxe-cell-upper-corner-mark.__left) {
  left: -5px;
}

:deep(.vxe-cell-upper-corner-mark.__right) {
  right: -5px;
}

:deep(.vxe-table--render-default.border--inner .vxe-table--border-lin),
:deep(.vxe-table--render-default .vxe-table--border-line) {
  display: v-bind(borderSync);
}

/* 表尾 */
:deep(.vxe-footer--row) {
  background-color: #f5f5f5;
}
/* 赤字 */
:deep(.red-font) {
  color: red !important;
  .el-link {
    color: red;
  }
}
:deep(.vxe-table--render-default .vxe-body--column.col--selected) {
  box-shadow: none;
}
:deep(.vxe-table) {
  overflow: hidden;
}

:deep(.vxe-table--render-default .vxe-header--column.col--center) {
  text-align: left !important;
}
:deep(.vxe-table--render-default .vxe-body--column.col--center) {
  text-align: left !important;
}
:deep(.vxe-table--render-default .vxe-footer--column.col--center) {
  text-align: left !important;
}
:deep(.vxe-header--column.col--ellipsis.col--center .vxe-cell) {
  justify-content: flex-start !important;
}
:deep(.vxe-table--render-default .vxe-tree-cell) {
  padding-left: 0 !important;
}
:deep(.vxe-header--column .vxe-cell--edit-icon) {
  display: none !important;
}
.copySvg {
  margin-right: 2px;
  cursor: pointer;
}
.custom-max-height {
  :deep(.vxe-table--render-default:not(.is--empty).is--footer.is--scroll-x
      .vxe-table--body-wrapper) {
    max-height: calc(100vh - 460px) !important;
  }
  :deep(.vxe-table--render-wrapper
      > .vxe-table--main-wrapper
      > .vxe-table--body-wrapper) {
    max-height: calc(100vh - 360px) !important;
  }
  :deep(.vxe-table--render-default
      .vxe-table--body-wrapper.fixed-right--wrapper),
  :deep(.vxe-table--render-default
      .vxe-table--body-wrapper.fixed-left--wrapper) {
    max-height: calc(100vh - 360px) !important;
  }
}
</style>
