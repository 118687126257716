/**
 * @module api-scm:buyInAndOutWarehouse/inWarehouse
 */
import service from '@/service'

/**
 * 采购入库   列表查询
 * @param {{
 * keywords:string,
 * limit:number,
 * page:number,
 * sort:string
 * }} params
 */
export function getList(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/getList',
    method: 'post',
    data
  })
}

export function getListDown(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/getPullList',
    method: 'get',
    params: { code: id }
  })
}

/**
 * @param {number} id
 * @description 作废
 */
export function invalid(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/cancellation',
    method: 'post',
    data: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * @param {object} data
 * @description 下推
 */
export function pushDown(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/purchaseApplyReturn',
    method: 'post',
    data
  })
}

/**
 * 新增物流信息
 */
export function saveLogistics(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/saveLogistics',
    method: 'post',
    data
  })
}

/**
 * 查询物流信息
 * @param {{id:nubmer|string}} params
 */
export function getLogistics(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/selectLogisticsList',
    method: 'get',
    params
  })
}

/**
 * @description 获取日志
 */
export function getOperationLog(params) {
  return service['api-scm']({
    url: '/public/order/getOperationLog',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {*} data
 * @returns
 */
export function update(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/update1',
    method: 'post',
    data
  })
}

// 详情查询
export function info(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/info',
    method: 'get',
    params: { id }
  })
}
/**
 * @param {Array<number|string>} ids
 * @description 提交
 */
export function submit(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/submit',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 审核驳回
 * @param {*} data
 * @returns
 */
export function reject(data) {
  return service['api-scm']({
    url: '/public/activiti/warehouseEntryReceipts/reject',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function qualityList(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/getQualityList',
    method: 'get',
    params
  })
}

export function saveQuality(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/saveQuality',
    method: 'post',
    data
  })
}

export function updateQuality(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/updateQuality',
    method: 'post',
    data
  })
}

/**
 * @param {{ids:string}} params
 * @description 删除
 */
export function remove(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/delete',
    method: 'delete',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {number}  - id
 */
export function removeQuality(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/deleteQuality',
    method: 'delete',
    params: { id },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function qualityInfo(id) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/infoQuality',
    method: 'get',
    params: { id }
  })
}

export function saveQualityInfo(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/update/updateQualityDetail',
    method: 'put',
    data
  })
}

/**
 * @param {string} ids
 * @description 结束流程（结束审核流）
 */
export function withdraw(ids) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/withdraw',
    method: 'post',
    data: { ids },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {array<string>} ids
 * @description 打印
 */
export function printBatch(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/printBatch',
    method: 'post',
    data
  })
}

export function printLabel(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/printLabel',
    method: 'post',
    data
  })
}
// /**
//  * @description 导入入库单
//  * 自动上传无需配置 axios 请求，只需要api地址 传递给 @see UploadFileDialog 组件通过 action 自动上传即可
//  */
// export function importData() {
//   return '/scm/purchaseWarehouse/warehouseEntryReceipts/importOrderLogisticsData'
// }

/**
 * @description 导出入库单
 */
export function exportData(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/exportOrderLogisticsData',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @description 手动对接
 */
export function manualDock(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/manualDock',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 根据入库单编码获取对应的入库记录
 * @param {params} params - 入库单编码
 */
export function getAllRecordByEntryCode(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/getAllRecordByEntryCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 确认拆单提醒
 */
export function getRemainRules(params) {
  return service['api-web']({
    url: '/cc/orderRemainCenter/get/getRemainRules',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @description 商品信息导出
 */
export function exportProductInformation(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/exportProductInformation',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 物流信息导出
 */
export function exportLogistics(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/exportLogistics',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * @description 质检信息导出
 */
export function exportQuality(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/exportQuality',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/json' }
  })
}

/**
 * 管易退货单查询
 */
export function getGuanYiTradeReturn(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/getGuanYiPurchaseWarehousing',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 批量查看物流详情
 */
export function batchSelectLogisticsList(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/get/batchSelectLogisticsList',
    method: 'post',
    data,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/*
 * 管易退货单查询
 */
export function updateAllWarehousing(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/updateAllWarehousing',
    method: 'post',
    data
  })
}

/**
 * @description 下载物流导入模板
 */
export function exportInformationTemplateData(params) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/downloadLogisticInformationTemplate',
    method: 'get',
    params,
    responseType: 'blob', // 文件流接收类型声明转成 blob
    saveAs: true // 文件流是否通过浏览器下载
  })
}

/**
 * @description 导入物流信息
 */
export function batchImportLogisticInformation(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/batchImportLogisticInformation',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 导入入库单
 */
export function importData(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/importOrderLogisticsData',
    method: 'post',
    data,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * @description 查询入库单产品
 */
export function orderProduct(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/query/orderProduct',
    method: 'post',
    data
  })
}

/**
 * @description 商品入库记录导出
 */
export function productWarehouseEntryRecordExport(data) {
  return service['api-scm']({
    url: '/scm/purchaseWarehouse/warehouseEntryReceipts/productWarehouseEntryRecordExport',
    method: 'post',
    data
  })
}
