/**
 * @module api-web:auto-complete
 * @description 模糊查询
 */
import service from '@/service'

/**
 * 供应商
 * @param {{productCodes:string,supplierName:string}} params
 */
export function getSupplierList(params) {
  return service['api-web']({
    url: '/public/data/getSupplierList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getUserPullList(params) {
  return service['api-web']({
    url: '/public/hr/getUserPullList',
    method: 'get',
    params
  })
}

/**
 * 供应商
 * @param {{supplierName:string}} params
 */
export function getSupplierInformationPullList(params) {
  return service['api-web']({
    url: '/public/data/getSupplierInformationPullList',
    method: 'get',
    params
  })
}

/**
 * 供应商
 * @param {{supplierName:string}} params
 */
export function getSupplierInformationLimitPullList(params) {
  return service['api-web']({
    url: '/public/data/getSupplierInformationLimitPullList',
    method: 'get',
    params
  })
}

/**
 * 供应商
 * @param {{supplierName:string}} params
 */
export function getSupplierListBySkuCode(params) {
  return service['api-web']({
    url: '/public/data/getSupplierListBySkuCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 商品下拉
export function getAncillaryName(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryName',
    method: 'get',
    params
  })
}

/**
 * 模糊查询商品code的下拉
 * @param {{code?:string,supplierCode?:string}} params
 */
export function getProductCode(params) {
  return service['api-web']({
    url: '/public/product/getProductCode',
    method: 'get',
    params
  })
}

/**
 * 查询商品code的下拉
 * @param {{code?:string,supplierCode?:string}} params
 */
export function queryAllSupplyByCode(params) {
  return service['api-web']({
    url: '/public/product/queryAllSupplyByCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 采购订单下单页面查询sku
 * @param {{productCode?:string,supplierCode:string}} params
 */
export function queryOrderSku(params) {
  return service['api-web']({
    url: '/public/product/queryOrderSku',
    method: 'get',
    params
  })
}
/**
 * 采购订单下单页面查询sku(包含公用 sku)
 * @param {{productCode?:string,supplierCode:string}} params
 */
export function queryOrderAndSharedSku(params) {
  return service['api-web']({
    url: '/public/product/queryOrderAndSharedSku',
    method: 'get',
    params
  })
}
/**
 * @param {{code:string,flag:(0|1)}} params
 * @description 模糊查询商品
 */
export function getAncillaryCode(params) {
  return service['api-web']({
    url: '/public/product/getAncillaryCode',
    method: 'get',
    params
  })
}

/**
 * @param {{code:string,flag:(0|1)}} params
 * @description 模糊查询商品
 */
export function queryByProductCode(params) {
  return service['api-web']({
    url: '/public/product/queryByProductCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *
 * @param {{skuCode: string, warehouseCode: string}} params
 * @description 根据仓库查询商品
 */
export function getSkuByWarehouseCode(params) {
  return service['api-web']({
    url: '/public/product/getSkuByWarehouseCode',
    method: 'get',
    params
  })
}
/**
 * @param {{code:string,flag:(0|1)}} params
 * @description 精确查询商品
 */
export function getExactProductByCode(params) {
  return service['api-web']({
    url: 'public/product/getExactProductByCode',
    method: 'get',
    params
  })
}

/**
 * 仓库
 * - 新疆仓部门过滤
 */
export function getWarehouse(params) {
  return service['api-web']({
    url: '/public/data/getWarehouse',
    method: 'get',
    params
  })
}

/**
 * 仓库
 * - 新疆仓部门过滤
 */
export function getWarehouseDropDown(params) {
  return service['api-web']({
    url: '/public/data/getWarehouseDropDown',
    method: 'get',
    params
  })
}

/**
 * 批次号
 */
export function getInventoryProductPullList(params) {
  return service['api-web']({
    url: '/public/warehouse/getInventoryProductPullList',
    method: 'get',
    params
  })
}

/**
 * 库存状态
 */
export function getWarehouseStatusPullList(params) {
  return service['api-web']({
    url: '/public/data/getWarehouseStatusPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{keywords:string}} params
 */
export function getUserlists(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params: {
      ...params,
      flag: 1
    },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 税率 (不显示禁用)
 * @param {{documentStatus:(1|2|3|4),keywords:string,limit:number,page:number}} params
 */
export function getTaxRatePullList(params) {
  return service['api-web']({
    url: '/public/data/getTaxRatePullList',
    method: 'get',
    params
  })
}

/**
 * 商品标签
 * @param {{label:string,flag:(0|1)}}
 */
export function getProductLabelManageOpts(params) {
  return service['api-web']({
    url: '/public/product/getProductLabelManage',
    method: 'get',
    params
  })
}

/**
 * 客户信息
 * @param {{name:string,flag:(0|1)}}
 */
export function getClientPull(params) {
  return service['api-web']({
    url: '/public/data/getClientPull',
    method: 'get',
    params: {
      name: params.name,
      flag: 1
    }
  })
}

/**
 * 客户信息(快速获取)
 * @param {{name:string,flag:(0|1)}}
 */
export function getClientQuick(params) {
  return service['api-web']({
    url: '/public/data/getClientPullByName',
    method: 'get',
    params: {
      name: params.name,
      flag: 1
    }
  })
}

/**
 * 客户信息
 * @param {{name:string,flag:(0|1)}}
 */
export function getClientLimitPull(params) {
  return service['api-web']({
    url: '/public/data/getClientLimitPull',
    method: 'get',
    params: {
      name: params.name,
      flag: 1
    }
  })
}

/**
 * 往来单位
 * @param {{type:(1|2|3),name:string,flag:(0|1)}} params
 * type:1-客户 2-供应商 3-物流商
 */
export function getDealingClient(params) {
  return service['api-web']({
    url: '/public/data/getDealingClient',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 计量单位
 * @param {{flag:(0|1)}} params
 */
export function getUnitOfMeasurement(params) {
  return service['api-web']({
    url: '/public/getUnitOfMeasurement',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 费用项目(不显示禁用)
 * @param {{nameOrCode:string}}
 */
export function getCostItem(params) {
  return service['api-web']({
    url: '/public/fin/getCostItem',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
/**
 * 源单编号 (付款)
 * @param {{sourceType:(1|2|3|4),sourceCode:string}} 	params
 * @description
 * - sourceType：源单类型(必传) 1采购单,2.3查财务应付单,4查物流单
 * - sourceCode：源单编号
 */
export function getPaymentSourceCode(params) {
  return service['api-web']({
    url: '/public/fin/getSourceCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 源单编码（收款）
 * @param {{sourceType:(1|2),sourceCode:string}} 	params
 * @description
 * - sourceType：源单类型(必传) 1暂估应收 2销售订单
 * - sourceCode：源单编号
 */
export function getReceivableSourceCode(params) {
  return service['api-web']({
    url: '/public/fin/getReceivableSourceCode',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{page:number,limit:number,keywords:string}} params
 * @description (销售)公司/组织远程 搜索
 */
export function getCompanyPullList(params) {
  return service['api-web']({
    url: '/public/data/getCompanyPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @param {{page:number,limit:number,keywords:string}} params
 * @description 公司/组织远程 搜索
 */
export function fetchCompanyPullList(params) {
  return service['api-web']({
    url: '/public/getCompanyPullList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 仓库
 * @param {{warehouseName:string}} params
 * @description  排除供应商直发仓，供应商库存直发仓
 */
export function getExcludeDirectWarehouseDropDown(params) {
  return service['api-web']({
    url: '/public/data/getExcludeDirectWarehouseDropDown',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 *  科目编码
 * @param {{subjectCode:string}} params
 */
export function getSubjectCodes(params) {
  return service['api-web']({
    url: '/public/fin/getSubjects',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * @returns
 * @description 供应链中生产管理已启用的供应商
 */
export function getSupplierListByNameAndDisableStatus(params) {
  return service['api-web']({
    url: '/public/data/getSupplierListByNameAndDisableStatus',
    method: 'get',
    params
  })
}

/**
 * @param {{keywords:string=}} params
 * @description 下拉选项：用户 (根据真实姓名 name 字段 模糊查询)
 */
export function getUserOpts(params) {
  return service['api-web']({
    url: '/public/findUserList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getDocumentStatusOpts(params) {
  return service['api-web']({
    url: '/public/sys/documentStatus',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getDocumentTypeSalesOpts(params) {
  return service['api-web']({
    url: '/public/sys/documentTypeSalesOrder',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getOrderProgressOpts(params) {
  return service['api-web']({
    url: '/public/sys/orderProgress',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getUserListOpts(params) {
  return service['api-web']({
    url: '/public/getUserlists',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export function getWarehouseLikeNameOpts(params) {
  return service['api-web']({
    url: '/public/warehouse/getWarehouseLikeName',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
// 库位代码
export function getInventoryLikeNameOpts(params) {
  return service['api-web']({
    url: '/public/warehouse/getInventoryLikeName',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

// 货主
export function getCompanyPullOnListOpts(params) {
  return service['api-web']({
    url: '/public/data/getCompanyPullOnList',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 仓库（所有所有非创建状态的仓库）
 */
export function getNotCreatedWarehouseOpts(params) {
  return service['api-web']({
    url: '/allPublic/getAllNonCreatedWarehouseNames',
    method: 'get',
    params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
